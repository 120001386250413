import React, { useEffect, useState } from 'react'
import Header from '../Common/Header'
import Footer from '../Common/Footer'
import axios from 'axios'
import { BaseUrl } from '../Api/BaseUrl'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import ScrollToTop from '../ScrollToTop'
import Whatsapp from '../Common/Whatsapp'
import Loader from '../Common/Loader'

export default function Career() {
    const [jobOpening, setJobOpening] = useState([]);
    const [loading, setLoading] = useState(true);
    let [formValue, setValue] = useState({
        name: '',
        phone: '',
        email: '',
        file: ''
    })
    const [errorMessage, setErrorMessage] = useState('');

    const validateForm = () => {
        // Reset error message
        setErrorMessage('');

        // Name validation: only alphabets
        if (!/^[a-zA-Z\s]+$/.test(formValue.name)) {
            setErrorMessage('Name must contain only alphabets');
            return false;
        }

        // Phone validation: only numbers
        if (!/^\d+$/.test(formValue.phone)) {
            setErrorMessage('Phone number must contain only numbers');
            return false;
        }

        // Email validation: email format
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!emailRegex.test(formValue.email)) {
            setErrorMessage('Invalid email format');
            return false;
        }

        // File validation: PDF format and size less than 2MB
        const allowedFileTypes = ['application/pdf'];
        const maxFileSize = 2 * 1024 * 1024; // 2MB

        if (
            !formValue.file ||
            !allowedFileTypes.includes(formValue.file.type) ||
            formValue.file.size > maxFileSize
        ) {
            setErrorMessage('Invalid file format or size (max 2MB)');
            return false;
        }

        return true; // Form is valid
    };
    const submitHandler = async (event) => {
        event.preventDefault();
        if (!validateForm()) {
            // If form validation fails, do not proceed with submission
            NotificationManager.error(errorMessage, 'Please Check Form!!', 3000);
            return;
        }

        try {
            const formData = new FormData();
            formData.append('name', formValue.name);
            formData.append('phone', formValue.phone);
            formData.append('email', formValue.email);
            formData.append('file', formValue.file);

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'accept': 'application/json'
                },
            };

            setValue({
                name: '',
                phone: '',
                email: '',
                file: null
            });
            event.target.reset();
            NotificationManager.success('Form submitted successfully', 'Success', 3000);
            const response = await axios.post(`${BaseUrl}/careerSubmit`, formData, config);
            console.log(response.data);
        } catch (error) {
            if (error.response && error.response.status === 429) {
                console.error('Too Many Requests: Please try again later.');
                NotificationManager.error('Too Many Requests: Please try again later.', 'Error', 3000);
            } else if (error.response && error.response.status === 422) {
                console.log('Validation Error:', error.response.data.errors);
                NotificationManager.error('Validation Error: Check the form fields', 'Error', 3000);
            } else {
                console.error('Submission Error:', error);
                NotificationManager.error('Submission Error. Please try again later.', 'Error', 3000);
            }
        }
    };


    const jobData = () => {
        axios.get(BaseUrl + '/job-opening')
            .then((res) => res.data)
            .then((finalRes) => {
                setJobOpening(finalRes.data);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    useEffect(() => {
        jobData();
        setLoading(false)
    }, []);
    return (
        <div>
            <ScrollToTop />
            <Whatsapp />
            <div className="offcanvas offcanvas-start" data-bs-backdrop="static" tabindex="-1" id="staticBackdrop" aria-labelledby="staticBackdropLabel">
                <div className="offcanvas-header">
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <div>
                        <form action="#" onSubmit={submitHandler}>
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12 col-12">
                                            <div className="form-group">
                                                <label>Your Name</label>
                                                <input
                                                    type="text"
                                                    name='name'
                                                    className="form-control"
                                                    placeholder="Your Name"
                                                    required
                                                    onChange={(e) => {
                                                        let obj = { ...formValue };
                                                        obj['name'] = e.target.value;
                                                        setValue(obj);
                                                    }}
                                                    value={formValue.name} />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <div className="form-group">
                                                <label>Phone Number</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name='phone'
                                                    required
                                                    placeholder="Enter Number"
                                                    onChange={(e) => {
                                                        let obj = { ...formValue };
                                                        obj['phone'] = e.target.value;
                                                        setValue(obj)
                                                    }}
                                                    value={formValue.phone} />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <div className="form-group">
                                                <label>Email Address</label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    name='email'
                                                    required
                                                    placeholder="Enter Email"
                                                    onChange={(e) => {
                                                        let obj = { ...formValue };
                                                        obj['email'] = e.target.value;
                                                        setValue(obj)
                                                    }}
                                                    value={formValue.email} />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label>Upload CV</label>
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    placeholder="Enter Your CV"
                                                    onChange={(e) => {
                                                        const file = e.target.files[0];
                                                        let obj = { ...formValue };
                                                        obj['file'] = file;
                                                        setValue(obj);
                                                    }}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                    <button type="submit" className="btn-primary">Apply</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <Header />
            {loading ? (
                <Loader />
            ) : (
            <>
                <div className="fixed d-none  top-0 left-0 bg-black opacity-75 w-screen h-screen z-50 bkDrop"></div>
                <div
                    className="Modal d-none  w-5/6 md:w-2/6  h-auto fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-3xl flex flex-col justify-center items-center relaive py-12 px-10">
                    <button className="font-bold absolute rtl:left-4 ltr:right-4 top-4 text-2xl"> <svg stroke="currentColor"
                        fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" fill-rule="evenodd" height="1em"
                        width="1em" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M512 64c247.4 0 448 200.6 448 448S759.4 960 512 960 64 759.4 64 512 264.6 64 512 64Zm0 76c-205.4 0-372 166.6-372 372s166.6 372 372 372 372-166.6 372-372-166.6-372-372-372Zm128.013 198.826c.023.007.042.018.083.059l45.02 45.019c.04.04.05.06.058.083a.118.118 0 0 1 0 .07c-.007.022-.018.041-.059.082L557.254 512l127.861 127.862a.268.268 0 0 1 .05.06l.009.023a.118.118 0 0 1 0 .07c-.007.022-.018.041-.059.082l-45.019 45.02c-.04.04-.06.05-.083.058a.118.118 0 0 1-.07 0c-.022-.007-.041-.018-.082-.059L512 557.254 384.14 685.115c-.042.041-.06.052-.084.059a.118.118 0 0 1-.07 0c-.022-.007-.041-.018-.082-.059l-45.02-45.019c-.04-.04-.05-.06-.058-.083a.118.118 0 0 1 0-.07c.007-.022.018-.041.059-.082L466.745 512l-127.86-127.86a.268.268 0 0 1-.05-.061l-.009-.023a.118.118 0 0 1 0-.07c.007-.022.018-.041.059-.082l45.019-45.02c.04-.04.06-.05.083-.058a.118.118 0 0 1 .07 0c.022.007.041.018.082.059L512 466.745l127.862-127.86c.04-.041.06-.052.083-.059a.118.118 0 0 1 .07 0Z">
                        </path>
                    </svg> </button>
                    <h3 className="font-bold text-2xl">Apply this Job</h3>
                    <div className="mt-6 w-full"><input required="" pattern="^\w+([\w ]*\w)*$" minlength="4" type="text" name="Name"
                        autocomplete="true" className="w-full px-6 py-3 text-mainColor bg-white shadow-lg rounded-full"
                        placeholder="Name" value="" /></div>
                    <div className="mt-6 w-full">
                        <div className=" react-tel-input w-full px-6 py-3 text-mainColor bg-white shadow-lg rounded-full">
                            <div className="special-label">Phone</div><input className="form-control " placeholder="Enter phone number"
                                type="tel" value="+971" />
                            <div className="flag-dropdown ">
                                <div className="selected-flag" title="United Arab Emirates: + 971" tabindex="0" role="button"
                                    aria-haspopup="listbox">
                                    <div className="flag ae">
                                        <div className="arrow"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-6 w-full"><input required="" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"
                        type="email" name="email" autocomplete="true"
                        className="w-full px-6 py-3 text-mainColor bg-white shadow-lg rounded-full" placeholder="E-mail"
                        title="Please enter a valid email address" value="" /></div>
                    <div className="w-full my-6  px-6 py-3 text-mainColor bg-white shadow-lg rounded-full flex flex-wrap items-center">
                        <input type="file" id="upload" hidden=""
                            accept="image/png, image/jpeg, application/pdf, application/vnd.ms-excel" /><label for="upload"
                                className=" w-full cursor-pointer font-bold flex items-center justify-between gap-2 text-gray-500"> Upload
                            CV <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024"
                                className="text-2xl" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M518.3 459a8 8 0 0 0-12.6 0l-112 141.7a7.98 7.98 0 0 0 6.3 12.9h73.9V856c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V613.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 459z">
                                </path>
                                <path
                                    d="M811.4 366.7C765.6 245.9 648.9 160 512.2 160S258.8 245.8 213 366.6C127.3 389.1 64 467.2 64 560c0 110.5 89.5 200 199.9 200H304c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8h-40.1c-33.7 0-65.4-13.4-89-37.7-23.5-24.2-36-56.8-34.9-90.6.9-26.4 9.9-51.2 26.2-72.1 16.7-21.3 40.1-36.8 66.1-43.7l37.9-9.9 13.9-36.6c8.6-22.8 20.6-44.1 35.7-63.4a245.6 245.6 0 0 1 52.4-49.9c41.1-28.9 89.5-44.2 140-44.2s98.9 15.3 140 44.2c19.9 14 37.5 30.8 52.4 49.9 15.1 19.3 27.1 40.7 35.7 63.4l13.8 36.5 37.8 10C846.1 454.5 884 503.8 884 560c0 33.1-12.9 64.3-36.3 87.7a123.07 123.07 0 0 1-87.6 36.3H720c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h40.1C870.5 760 960 670.5 960 560c0-92.7-63.1-170.7-148.6-193.3z">
                                </path>
                            </svg> </label></div><button
                                className=" px-6 py-2 text-xl rounded-full text-center cursor-pointer bg-black text-[#D4B071] font-bold mt-4"
                                type="button"> Submit </button>
                </div>
                <div className="breadcrumb">
                    <div className="container">
                        <div className="bread-crumb-head">
                            <div className="breadcrumb-title">
                                <h1 className='text-white text-uppercase '>Career at Namaste Properties</h1>
                            </div>
                            {/* <div className="breadcrumb-list">
                            <ul>
                                <li><a href="index-2.html">Home </a></li>
                                <li>Blog</li>
                            </ul>
                        </div> */}
                        </div>
                        <div className="breadcrumb-border-img">
                            <img src="https://dreamsestate.dreamstechnologies.com/html/assets/img/bg/line-bg.png" alt="Line Image" />
                        </div>
                    </div>
                </div>
                <div className="mx-auto">
                    <div className="w-full mt-16 flex flex-col justify-between items-center gap-y-5">
                        <div>
                            <h3 className="font-bold mb-4 text-xl md:text-3xl text-center" style={{ color: '#ebbb67' }}>Current Job Opportunities!</h3>
                            <p className="text-center text-lg max-w-2xl mx-auto" style={{ color: '#fff' }}>We are seeking motivated individuals who excel in
                                dynamic, results-oriented environments. Our organization offers ambitious candidates an
                                exceptional opportunity to earn substantial, tax-free income.</p>
                        </div>
                        <div className='w-100 '>
                            {
                                jobOpening.map((job, index) => {
                                    return (
                                        <div
                                            className="w-full flex flex-col md:flex-row items-center justify-center py-4 bg-[#fbfbfb] rounded-2xl md:rounded-full shadow-xl px-10 mb-3 ">
                                            <div className="w-full text-center md:text-left md:w-2/5 p-2 font-bold text-xl" style={{ color: '#ebbb67' }}>{job.title}
                                            </div>
                                            <div className="w-full md:w-1/5 p-2 flex justify-center items-center gap-x-2"><svg stroke="currentColor"
                                                fill="currentColor" stroke-width="0" viewBox="0 0 24 24" className="w-5 h-5 text-black"
                                                height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z">
                                                </path>
                                                <path d="M13 7h-2v6h6v-2h-4z"></path>
                                            </svg>{job.position}</div>
                                            <div className="w-full md:w-1/5 p-2 flex justify-center items-center gap-x-2"><svg stroke="currentColor"
                                                fill="currentColor" stroke-width="0" viewBox="0 0 20 20" aria-hidden="true"
                                                className="w-5 h-5 text-black" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd"
                                                    d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                                                    clip-rule="evenodd"></path>
                                            </svg>{job.location}</div>
                                            <button className="btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#staticBackdrop" aria-controls="staticBackdrop">
                                                Apply
                                            </button>
                                        </div>
                                    )
                                })
                            }


                        </div>
                    </div>
                </div>
                <div className="mx-auto mt-14 flex flex-col md:flex-row justify-center items-center mb-5 ">
                    <div>
                        <h3 className="font-bold mb-4 text-2xl md:text-3xl text-center" style={{ color: '#ebbb67' }}>Future Job Opportunities!</h3>
                        <p className="text-center text-md max-w-2xl mx-auto" style={{ color: '#fff' }}>If you haven't come across a suitable and currently
                            available job opportunity, we kindly request you to submit your curriculum vitae (CV) using the form
                            provided below or send it via email to <a href="info@namaste-properties.com"
                                className="" style={{ color: '#ebbb67' }}>info@namaste-properties.com .</a> Your CV will be carefully reviewed and
                            considered for any potential openings that match your qualifications and experience. Thank you for
                            your interest in our company, and we look forward to reviewing your application.</p>
                    </div>
                    <div
                        className="mt-8 md:mt-0 w-full md:w-1/2 flex flex-col justify-center items-center relaive p-0 md:py-12 md:px-10 mx-auto">
                        <h3 className="font-bold text-2xl text-center" style={{ color: '#ebbb67' }}>Find your next job with</h3>
                        <h3 className="font-bold text-2xl text-center" style={{ color: '#ebbb67' }}>Namaste Properties</h3>
                        {/* {errorMessage && <div className="text-red-500 mb-4">{errorMessage}</div>} */}
                        <form action="#" onSubmit={submitHandler}>
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12 col-12">
                                            <div className="form-group">
                                                <label>Your Name</label>
                                                <input
                                                    type="text"
                                                    name='name'
                                                    className="form-control"
                                                    placeholder="Your Name"
                                                    required
                                                    onChange={(e) => {
                                                        let obj = { ...formValue };
                                                        obj['name'] = e.target.value;
                                                        setValue(obj);
                                                    }}
                                                    value={formValue.name} />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <div className="form-group">
                                                <label>Phone Number</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name='phone'
                                                    required
                                                    placeholder="Enter Number"
                                                    onChange={(e) => {
                                                        let obj = { ...formValue };
                                                        obj['phone'] = e.target.value;
                                                        setValue(obj)
                                                    }}
                                                    value={formValue.phone} />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <div className="form-group">
                                                <label>Email Address</label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    name='email'
                                                    required
                                                    placeholder="Enter Email"
                                                    onChange={(e) => {
                                                        let obj = { ...formValue };
                                                        obj['email'] = e.target.value;
                                                        setValue(obj)
                                                    }}
                                                    value={formValue.email} />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label>Upload CV</label>
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    placeholder="Enter Your CV"
                                                    onChange={(e) => {
                                                        const file = e.target.files[0];
                                                        let obj = { ...formValue };
                                                        obj['file'] = file;
                                                        setValue(obj);
                                                    }}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                    <button type="submit" className="btn-primary">Apply</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </>
            )}
            <Footer />
            <NotificationContainer />
        </div>
    )
}
