import React, { useEffect, useState } from 'react'
import Header from '../Common/Header'
import Footer from '../Common/Footer'
import { BaseUrl } from '../Api/BaseUrl'
import axios from 'axios'
import { ImageBaseUrl } from '../Api/ImageBaseUrl'
import { Link } from 'react-router-dom'
import ScrollToTop from '../ScrollToTop'
import Whatsapp from '../Common/Whatsapp'
import Loader from '../Common/Loader'
const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
    return formattedDate;
  };
export default function Blog() {
    let [blog, setBlog] = useState([])
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    let [lastPage, setLastPage] = useState()
    const blogData = async () => {
        try {
            const response = await axios.get(`${BaseUrl}/blogs?page=${currentPage}`);
            const finalRes = response.data;
            const bData = finalRes.data.blogs.data;
            console.log(response)
            setBlog(bData);
            console.log(bData);
            setLastPage(finalRes.data.blogs.last_page);
        } catch (error) {
            if (error.response && error.response.status === 429) {
                console.error('Too Many Requests: Please try again later.');
            } else {
                console.error('Error fetching blog data:', error);
            }
        }
    };

    useEffect(() => {
        blogData();
        setLoading(false);
    }, [currentPage])
    const handlePageChange = (newPage) => {
        // Ensure newPage is within valid bounds
        if (newPage >= 1 && newPage <= lastPage) {
            setCurrentPage(newPage);
        }
    };
    const renderPagination = () => {
        const paginationButtons = [];

        // Previous Page Button
        paginationButtons.push(
            <li key="prev" className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
                    Previous
                </button>
            </li>
        );

        // Numbered Page Buttons
        for (let page = 1; page <= lastPage; page++) {
            paginationButtons.push(
                <li key={page} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(page)}>
                        {page}
                    </button>
                </li>
            );
        }

        // Next Page Button
        paginationButtons.push(
            <li key="next" className={`page-item ${currentPage === lastPage ? 'disabled' : ''}`}>
                <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
                    Next
                </button>
            </li>
        );

        return paginationButtons;
    };
    return (
        <div>
            <ScrollToTop />
            <Whatsapp />
            <Header />
            {loading ? (
                <Loader />
            ) : (
                <>
                    <div className="breadcrumb">
                        <div className="container">
                            <div className="bread-crumb-head">
                                <div className="breadcrumb-title">
                                    <h2>Blog</h2>
                                </div>
                                <div className="breadcrumb-list">
                                    <ul>
                                        <li><Link to={'/'}>Home </Link></li>
                                        <li>Blog</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="breadcrumb-border-img">
                                <img src="https://dreamsestate.dreamstechnologies.com/html/assets/img/bg/line-bg.png" alt="Line Image" />
                            </div>
                        </div>
                    </div>
                    <section className="container mx-auto">
                        <div className="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:pt-20">
                            {
                                blog.map((blogdata, index) => {
                                    return (
                                        <div className="grid gap-8 lg:grid-cols-2 mb-3 ">

                                            <div className="flex justify-center items-center relative">
                                                <img
                                                    className="object-cover rounded-3xl w-full h-full col-span-2 shadow-lg" style={{ height: '350px', border: '5px solid #ebbb67' }}
                                                    src={ImageBaseUrl + '/' + blogdata.photo}
                                                    alt="The Merits of Buying an Apartment in Dubai" />
                                            </div>
                                            <div className="flex flex-col justify-center items-center lg:items-start">
                                                <div>
                                                    <h3 className="mb-2 text-center lg:text-left font-bold text-2xl md:text-3xl" style={{ color: '#ebbb67' }}>{blogdata.title} </h3>
                                                    <p className="text-center lg:text-left text-white">{formatDate(blogdata.updated_at)}</p>
                                                </div>
                                                <div ></div>
                                                <p className="text-sm text-gray-900 white-text lineClamp" dangerouslySetInnerHTML={{ __html: blogdata.description }}></p>

                                                <Link to={`/blog-details/${blogdata.slug}`} className="font-black w-fit mt-5 bg-white px-4 py-px border-[#D4B071] rounded-full hover:bg-black  transition-all duration-2000 ease-in-out"
                                                    href="/blogs/the-merits-of-buying-an-apartment-in-dubai">
                                                    <p>Read More</p>
                                                </Link>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </section>
                    <nav aria-label="Page navigation example" className='my-5 '>
                        <ul className="pagination flex justify-content-center ">
                            {renderPagination()}
                        </ul>
                    </nav>
                </>
            )}
            <Footer />
        </div>
    )
}
