import React, { useEffect, useState } from "react";
import lineBg from "../images/lineBg.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBehance,
  faFacebook,
  faFacebookF,
  faInstagram,
  faLinkedin,
  faPinterestP,
  faTiktok,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import logoImage from "../logo/logo.png";
import {
  faCircle,
  faEnvelope,
  faGlobe,
  faLocation,
  faLocationDot,
  faMailReply,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { BaseUrl } from "../Api/BaseUrl";
import axios from "axios";
import { ImageBaseUrl } from "../Api/ImageBaseUrl";
import ScrollToTop from "../ScrollToTop";
export default function Footer() {
  let [footerData, setfooterData] = useState([]);
  let [address, setAddress] = useState({});
  let [footerCat, setFooterCat] = useState([]);
  let [termPolicy, setTermPolicy] = useState([]);
  let [email, setemail] = useState();
  let footerRes = () => {
    axios
      .get(BaseUrl + "/common_data")
      .then((res) => res.data)
      .then((finalRes) => {
        setfooterData(finalRes.data.socialLink);
        setAddress(finalRes.data.generalSetting);
        setFooterCat(finalRes.data.project_category);
        setTermPolicy(finalRes.data.pages);
        console.log(finalRes.data.generalSetting.email);
        setemail(finalRes.data.generalSetting.email);
      })
      .catch((error) => {
        console.error("Error fetching footer data:", error);
      });
  };

  useEffect(() => {
    footerRes();
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // You can change this to 'auto' for an instant scroll
    });
  };
  const getSocialIcon = (socialName) => {
    switch (socialName.toLowerCase()) {
      case "instagram":
        return <FontAwesomeIcon icon={faInstagram} className="custom-icon" />;
      case "facebook":
        return <FontAwesomeIcon icon={faFacebookF} className="custom-icon" />;
      case "twitter":
        return <FontAwesomeIcon icon={faTwitter} className="custom-icon" />;
      case "tiktok":
        return <FontAwesomeIcon icon={faTiktok} className="custom-icon" />;
      case "linkedin":
        return <FontAwesomeIcon icon={faLinkedin} className="custom-icon" />;
      case "youtube":
        return <FontAwesomeIcon icon={faYoutube} className="custom-icon" />;
      default:
        // Default icon (you can change this to a generic icon or customize further)
        return <FontAwesomeIcon icon={faGlobe} className="custom-icon" />;
    }
  };
  return (
    <div>
      <ScrollToTop />
      <footer class="footer">
        <div class="footer-top">
          <div class="footer-border-img">
            <img src={lineBg} alt="image" />
          </div>
          <div class="container">
            <div class="row">
              {address !== undefined ? (
                <div class="col-lg-4 col-md-6 col-sm-8">
                  <a
                    aria-label="Go home"
                    title="Namaste Properties"
                    class="inline-flex items-center"
                    href="/"
                  >
                    <img
                      alt=""
                      loading="lazy"
                      width="250"
                      height="46"
                      decoding="async"
                      data-nimg="1"
                      class="mx-auto my-4"
                      style={{ color: "transparent" }}
                      src={ImageBaseUrl + "/" + address.footer_logo}
                    />
                  </a>

                  <div class="mt-4 lg:max-w-sm">
                    <p class="footerDetails text-md font-bold text-gray-800  ">
                      <p
                        class="flex items-center text-white"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          style={{ height: "5px" }}
                          icon={faCircle}
                        />{" "}
                        &nbsp;&nbsp;Trade License # 1310170
                      </p>
                      <p
                        class="flex items-center text-white"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          style={{ height: "5px" }}
                          icon={faCircle}
                        />{" "}
                        &nbsp;&nbsp;ORN # 40928
                      </p>

                      <p
                        class="flex items-center text-white"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          icon={faLocationDot}
                          style={{ height: "30px", marginRight:"10px"}}
                        />
                        {address.address}
                      </p>
                      <a
                        href="tel:97146052400"
                        class="flex items-center"
                        target="_blank"
                        rel="noreferrer"
                      >
                        
                        <span class="ltr text-white"> <FontAwesomeIcon
                          icon={faPhone}
                          style={{ height: "30px", marginRight:"10px"}}
                        />{address.phone}</span>
                      </a>
                      <a
                        href="mailto:{email}"
                        class="flex items-center text-white"
                        target="_blank"
                        rel="noreferrer"
                      ><FontAwesomeIcon
                      icon={faEnvelope}
                      style={{ height: "30px", marginRight:"10px"}}
                    />
                        {email}
                      </a>
                    </p>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div class="col-lg-3 col-md-3 col-sm-4">
                <div class="footer-widget-list">
                  <div class="footer-content-heading">
                    <h4>Categories</h4>
                  </div>
                  <ul className="ps-0">
                    {footerCat.map((cat, index) => {
                      return (
                        <li>
                          <Link
                            to={`/project/${cat.slug}`}
                            onClick={scrollToTop}
                            className="d-flex justify-content-center  align-items-center "
                          >
                            <FontAwesomeIcon
                              style={{ height: "5px" }}
                              icon={faCircle}
                            />
                            &nbsp;&nbsp;{cat.name}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>

              <div class="col-lg-2 col-md-4 col-sm-4">
                <div class="footer-widget-list">
                  <div class="footer-content-heading">
                    <h4>Quick Links</h4>
                  </div>
                  <ul className="ps-0">
                    <li>
                      <Link
                        to={"/about-us"}
                        className="d-flex justify-content-center  align-items-center "
                      >
                        <FontAwesomeIcon
                          style={{ height: "5px" }}
                          icon={faCircle}
                        />
                        &nbsp;&nbsp;About
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/project"}
                        className="d-flex justify-content-center  align-items-center "
                      >
                        <FontAwesomeIcon
                          style={{ height: "5px" }}
                          icon={faCircle}
                        />
                        &nbsp;&nbsp;Projects
                      </Link>
                    </li>
                    {termPolicy.map((term, index) => {
                      return (
                        <li>
                          <Link
                            to={`/term-and-condition/${term.slug}`}
                            className="d-flex justify-content-center  align-items-center "
                            onClick={scrollToTop}
                          >
                            <FontAwesomeIcon
                              style={{ height: "5px" }}
                              icon={faCircle}
                            />
                            &nbsp;&nbsp;{term.title}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>

              <div class="col-lg-3 col-md-6 col-sm-8">
                <div class="footer-widget footer-about">
                  <div class="footer-app-content">
                    <div class="social-links">
                      <h4>Connect with us</h4>
                      {footerData.length > 0 && (
                        <ul className="ps-2">
                          {footerData.map((social) => (
                            <li key={social.id}>
                              <a
                                href={social.link}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {getSocialIcon(social.name)}
                              </a>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="footer-bottom">
          <div class="container">
            <div class="footer-bottom-content">
              <div class="copyright">
                {address !== undefined ? <p>{address.copyright_text}</p> : ""}
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
