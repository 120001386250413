import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './App.css'
import App from './App';
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.css"
import "bootstrap/dist/js/bootstrap.bundle"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-notifications/lib/notifications.css';

import { BrowserRouter, RouterProvider, createBrowserRouter } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Buy from './pages/Buy';
import Rent from './pages/Rent';
import Project from './pages/Project';
import Areas from './pages/Areas';
import Developer from './pages/Developer';
import SellProperty from './pages/SellProperty';
import BuyProperty from './pages/BuyProperty';
import RentProperty from './pages/RentProperty';
import MeetTeam from './pages/MeetTeam';
import Faq from './pages/Faq';
import Blog from './pages/Blog';
import Career from './pages/Career';
import Contactus from './pages/Contactus';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermAndCondition from './pages/TermAndCondition';
import OffPlan from './pages/OffPlan';
import BuyingOffPlanGuide from './pages/BuyingOffPlanGuide';
import SellingGuide from './pages/SellingGuide';
import BuyingGuide from './pages/BuyingGuide';
import AreaDetails from './pages/AreaDetails';
import ProjectDetails from './pages/ProjectDetails';
import BlogDetails from './pages/BlogDetails';
import BuyProjectDetails from './pages/BuyProjectDetails';
import PlanGuide from './pages/PlanGuide';
import ScrollToTop from './ScrollToTop';
import Brochure from './pages/Brochure';

// let router=createBrowserRouter([
//   {
//     path:'/',
//     element:<Home/>
//   },
//   {
//     path:'/about-us',
//     element:<About/>
//   },
//   {
//     path:'/buy/:id',
//     element:<Buy/>
//   },
//   {
//     path:'/rent',
//     element:<Rent/>
//   },
//   {
//     path:'/off-plan',
//     element:<OffPlan/>
//   },
//   {
//     path:'/project/:slug?',
//     element:<Project/>
//   },
  
//   {
//     path:'/area',
//     element:<Areas/>
//   },
//   {
//     path:'/developer',
//     element:<Developer/>
//   },
//   {
//     path:'/sell-property/:slug',
//     element:<SellProperty/>
//   },
//   {
//     path:'/buy-property',
//     element:<BuyProperty/>
//   },
//   {
//     path:'/rent-property',
//     element:<RentProperty/>
//   },
//   {
//     path:'/meet-team',
//     element:<MeetTeam/>
//   },
//   {
//     path:'/faq',
//     element:<Faq/>
//   },
//   {
//     path:'/blog',
//     element:<Blog/>
//   },
//   {
//     path:'/blog-details/:slug',
//     element:<BlogDetails/>
//   },
//   {
//     path:'/career',
//     element:<Career/>
//   },
//   {
//     path:'/contact-us',
//     element:<Contactus/>
//   },
//   {
//     path:'/privacy-policy',
//     element:<PrivacyPolicy/>
//   },
//   {
//     path:'/term-and-condition/:slug',
//     element:<TermAndCondition/>
//   },
//   {
//     path:'/plan-guide/:slug',
//     element:<PlanGuide/>
//   },
//   {
//     path:'/selling-guide',
//     element:<SellingGuide/>
//   },
//   {
//     path:'/buying-guide',
//     element:<BuyingGuide/>
//   },
//   {
//     path:'/area-details/:slug',
//     element:<AreaDetails/>
//   },
//   {
//     path:'/project-details/:slug',
//     element:<ProjectDetails/>
//   },
//   {
//     path:'/buy-project-details',
//     element:<BuyProjectDetails/>
//   },
//   {
//     path:'/brochure',
//     element:<Brochure/>
//   }
// ])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter >
        <App tab="home" />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
