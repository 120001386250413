import React, { useEffect, useState } from 'react';
import logoImage from '../logo/logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight, faChevronUp, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { BaseUrl } from '../Api/BaseUrl';
import { ImageBaseUrl } from '../Api/ImageBaseUrl';
import ScrollToTop from '../ScrollToTop';


const Header = () => {
    let [headerData, setHeaderData] = useState([]);
    let [headerGuideData, setHeaderGuideData] = useState([]);
    let [menu, setMenu] = useState([]);
    let [headerDetails, setHeaderDetails] = useState()
    let [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isServicesSubMenuOpen, setIsServicesSubMenuOpen] = useState(false);
    const [isOthersSubMenuOpen, setIsOthersSubMenuOpen] = useState(false);
    const [isGuidesSubMenuOpen, setIsGuidesSubMenuOpen] = useState(false);    let headerRes = () => {
        axios.get(BaseUrl + '/common_data')
            .then((res) => res.data)
            .then((finalRes) => {
                setHeaderData(finalRes.data.services)
                setHeaderDetails(finalRes.data.generalSetting)
                setHeaderGuideData(finalRes.data.guide)
                setMenu(finalRes.data.types)
                // console.log(finalRes.data.types)
                // console.log(finalRes.data.generalSetting)
            })
    }

    useEffect(() => {
        headerRes()
    }, [])

    const filteredTypes = menu.filter(type => type.status === "1");
    const handleMobileToggle = (e) => {
        e.preventDefault()
        setIsMenuOpen(!isMenuOpen);
    };

    const handleSubMenuToggle = (e, submenu) => {
        e.preventDefault();

        switch (submenu) {
            case 'services':
                setIsServicesSubMenuOpen(!isServicesSubMenuOpen);
                setIsOthersSubMenuOpen(false);
                setIsGuidesSubMenuOpen(false);
                break;
            case 'others':
                setIsServicesSubMenuOpen(false);
                setIsOthersSubMenuOpen(!isOthersSubMenuOpen);
                setIsGuidesSubMenuOpen(false);
                break;
            case 'guides':
                setIsServicesSubMenuOpen(false);
                setIsOthersSubMenuOpen(true);
                setIsGuidesSubMenuOpen(!isGuidesSubMenuOpen);
                break;
            default:
                break;
        }
    };


    return (
        <div>
            <ScrollToTop />
            <header class="header header-fix shadow-sm ">
                <nav class="navbar navbar-expand-lg header-nav">
                    <div className="navbar-header">
                        <a
                            id="mobile_btn"
                            href=""
                            className={`${isMenuOpen ? 'menu-opened' : ''
                                }`}
                            onClick={handleMobileToggle}
                        >
                            <span className="bar-icon">
                                <span></span>
                                <span></span>
                                <span></span>
                            </span>
                        </a>
                        {headerDetails !== undefined ? (
                            <Link to={'/'} className="navbar-brand logo">
                                <img
                                    src={ImageBaseUrl + '/' + headerDetails.header_logo}
                                    alt="Logo"
                                />
                            </Link>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className={`main-menu-wrapper ${isMenuOpen ? 'show-menu' : ''}`}>
                        <div className="menu-header">
                            {headerDetails !== undefined ? (
                                <Link to={'/'} className="menu-logo">
                                    <img src={ImageBaseUrl + '/' + headerDetails.header_logo} className="img-fluid" alt="Logo" />
                                </Link>
                            ) : (
                                ''
                            )}
                            <a
                                id="menu_close"
                                className={`menu-close ${isMenuOpen ? '' : 'menu-opened'}`}
                                href=""
                                onClick={handleMobileToggle}
                            >
                                <FontAwesomeIcon icon={faTimes} />
                            </a>
                        </div>
                        <ul class="main-nav upperCase">
                            {filteredTypes.map((navMenu, index) => {
                                return (
                                    <li class="">
                                        <Link to={`/buy/${navMenu.id}`} >{navMenu.name}</Link>
                                    </li>
                                )

                            })}



                            {/* <li><Link to={'/project'}>Projects</Link></li> */}
                            <li><Link to={'/area'}>Areas</Link></li>
                            <li><Link to={'/developer'}>Developers</Link></li>

                            <li class={`has-submenu ${isServicesSubMenuOpen ? 'show-submenu' : ''}`}
                                onClick={(e) => handleSubMenuToggle(e, 'services')}>
                                <Link className='subMenuOpen'>Services
                                    <span className='iconDown'>
                                        <FontAwesomeIcon style={{ height: '12px', marginLeft: '5px' }} icon={faChevronDown} />
                                    </span>
                                    <span className='iconUp'>
                                        <FontAwesomeIcon style={{ height: '12px', marginLeft: '5px' }} icon={faChevronUp} />
                                    </span>
                                    <i class="fas fa-chevron-down"></i>
                                </Link>

                                <ul class="submenu">
                                    {headerData.map((service, index) => (
                                        <li key={service.id}>
                                            <Link to={`/sell-property/${service.slug}`}>{service.title}</Link>
                                        </li>
                                    ))}

                                </ul>


                            </li>
                            <li class={`has-submenu ${isOthersSubMenuOpen ? 'show-submenu' : ''}`}
                                onClick={(e) => handleSubMenuToggle(e, 'others')}>
                                <Link className='subMenuOpen'>Others
                                    <span className='iconDown'>
                                        <FontAwesomeIcon style={{ height: '12px', marginLeft: '5px' }} icon={faChevronDown} />
                                    </span>
                                    <span className='iconUp'>
                                        <FontAwesomeIcon style={{ height: '12px', marginLeft: '5px' }} icon={faChevronUp} />
                                    </span>
                                </Link>
                                <ul class="submenu">
                                    {/* <li>
                                        <Link to={'/brochure'}>Download Profile - PDF</Link>
                                    </li> */}
                                    <li><Link to={'/about-us'} >About Us</Link></li>
                                    <li><Link to={'/meet-team'} >Meet The Team</Link></li>
                                    <li className={`has-submenu ${isGuidesSubMenuOpen ? '' : 'guidesubmenu'}`}
                                        onClick={(e) => handleSubMenuToggle(e, 'guides')}>
                                        <Link className='submenuGuid subMenuOpen'>Guides
                                            <span className='iconDown'>
                                                <FontAwesomeIcon style={{ height: '12px', marginLeft: '5px' }} icon={faChevronDown} />
                                            </span>
                                            <span className='iconUp'>
                                                <FontAwesomeIcon style={{ height: '12px', marginLeft: '5px' }} icon={faChevronRight} />
                                            </span>
                                        </Link>
                                        <ul class="submenu">
                                            {headerGuideData.map((guide, index) => (
                                                <li key={guide.id}>
                                                    <Link to={`/plan-guide/${guide.slug}`}>{guide.slug}</Link>
                                                </li>
                                            ))}

                                        </ul>
                                    </li>
                                    <li><Link to={'/faq'} >FAQ's</Link></li>
                                    <li><Link to={'/blog'} >Blog</Link></li>
                                    <li><Link to={'/career'} >Careers</Link></li>
                                    <li><Link to={'/contact-us'} >Contact Us</Link></li>

                                </ul>
                            </li>

                        </ul>
                    </div>

                </nav>
            </header>
        </div>
    );
};

export default Header;