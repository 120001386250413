import React, { useEffect, useRef, useState } from 'react'
import Header from '../Common/Header'
import Footer from '../Common/Footer'
import { BaseUrl } from '../Api/BaseUrl';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import axios from 'axios';
import ScrollToTop from '../ScrollToTop';
import { Link } from 'react-router-dom';
import { ImageBaseUrl } from '../Api/ImageBaseUrl';
import Whatsapp from '../Common/Whatsapp';
import Loader from '../Common/Loader';

export default function Contactus() {
    let [contactData, setContactData] = useState()
    let [contactSec, setContactSec] = useState()
    const [loading, setLoading] = useState(true);
    const [contactImg,setContactImg]=useState()
    const contactRes = async () => {
        try {
            const response = await axios.get(BaseUrl + '/common_data');
            const finalRes = response.data;
            setContactImg(finalRes.data.contactPage.photo)
            setContactData(finalRes.data.contactPage);
            setContactSec(finalRes.data.generalSetting);
        } catch (error) {
            if (error.response && error.response.status === 429) {
                console.error('Too Many Requests: Please try again later.');
                NotificationManager.error('Too Many Requests: Please try again later.', 'Error', 3000);
            } else {
                console.error('Error fetching contact data:', error);
                NotificationManager.error('Error fetching contact data. Please try again later.', 'Error', 3000);
            }
        }
    };
    // const handleFormSubmit = (values) => {
    //     console.log(values); // Optional: Log the values to the console
    //     sendDataToBackend(values); // Send data to backend
    //   };
      
    useEffect(() => {
        contactRes()
        setLoading(false);
    }, [])

    let [formValue, setFormValue] = useState({
        name: '',
        phone: '',
        email: '',
        subject: '',
        message: ''
    });
    const [errorMessage, setErrorMessage] = useState('');

    const validateForm = () => {
        // Reset error message
        setErrorMessage('');

        // Name validation: only alphabets
        if (!/^[a-zA-Z\s]+$/.test(formValue.name)) {
            setErrorMessage('Name must contain only alphabets');
            return false;
        }

        // Phone validation: only numbers
        if (!/^\d+$/.test(formValue.phone)) {
            setErrorMessage('Phone number must contain only numbers');
            return false;
        }

        // Email validation: email format
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!emailRegex.test(formValue.email)) {
            setErrorMessage('Invalid email format');
            return false;
        }

        return true; // Form is valid
    };

    const contactInfoRef = useRef(null);
    const scrollToContactInfo = () => {
        contactInfoRef.current.scrollIntoView({ behavior: 'smooth' });
    };
    const contactSubmitHandler = async (event) => {
        event.preventDefault();
        if (!validateForm()) {
            // If form validation fails, do not proceed with submission
            NotificationManager.error(errorMessage, 'Please Check Form!!', 3000);
            return;
        }

        try {
            let formData = new FormData();
            formData.append('name', formValue.name);
            formData.append('phone', formValue.phone);
            formData.append('email', formValue.email);
            formData.append('subject', formValue.subject);
            formData.append('message', formValue.message);

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'accept': 'application/json'
                }
            };
            setFormValue({
                name: '',
                phone: '',
                email: '',
                subject: '',
                message: ''
            });
            NotificationManager.success('Form submitted successfully', 'Success', 3000);
            const response = await axios.post(`${BaseUrl}/contactSubmit`, formData, config);
            console.log(response.data);
        } catch (error) {
            if (error.response && error.response.status === 429) {
                console.error('Too Many Requests: Please try again later.');
                NotificationManager.error('Too Many Requests: Please try again later.', 'Error', 3000);
            } else if (error.response && error.response.status === 422) {
                console.log('Validation Error:', error.response.data.errors);
                NotificationManager.error('Validation Error: Check the form fields', 'Error', 3000);
            } else {
                console.error('Submission Error:', error);
                NotificationManager.error('Submission Error. Please try again later.', 'Error', 3000);
            }
        }
    };
    return (
        <div>
            <ScrollToTop />
            <Whatsapp />
            <Header />
            {loading ? (
                <Loader />
            ) : (
                <>
                    <div className="breadcrumb">
                        <div className="container">
                            <div className="bread-crumb-head">
                                <div className="breadcrumb-title">
                                    <h2>Contact Us</h2>
                                </div>
                                <div className="breadcrumb-list">
                                    <ul>
                                        <li><Link to={'/'}>Home </Link></li>
                                        <li>Contact Us</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="breadcrumb-border-img">
                                <img src="https://dreamsestate.dreamstechnologies.com/html/assets/img/bg/line-bg.png" alt="Line Image" />
                            </div>
                        </div>
                    </div>

                    <section className="section contact-section">
                        <div className="container">
                            {contactSec !== undefined && contactData !== undefined
                                ?
                                <div className="contact-content">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6">
                                            <div className="row">
                                                <div className="col-lg-12 d-flex">
                                                    <div className="card">
                                                        <div className="card-body contact-info flex-fill">
                                                            <h3>{contactData.title}</h3>

                                                            <Link onClick={scrollToContactInfo} className="btn-primary d-inline-block">Contact Us</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 ">
                                            <img src={ImageBaseUrl + '/' + contactImg} alt="Image" style={{ height: '500px' }} className='m-auto ' />
                                        </div>
                                    </div>
                                </div>
                                :
                                ''}

                        </div>
                    </section>

                    <section className="section contact-info-sec" ref={contactInfoRef}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6">
                                    <form action="#" onSubmit={contactSubmitHandler}>
                                        <div className="card">
                                            <div className="card-header">
                                                <h3>Get In Touch</h3>
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-lg-12 col-12">
                                                        <div className="form-group">
                                                            <label>Your Name</label>
                                                            <input type="text" className="form-control" placeholder="Your Name"
                                                                name='name'
                                                                onChange={(e) => {
                                                                    let obj = { ...formValue };
                                                                    obj['name'] = e.target.value;
                                                                    setFormValue(obj);
                                                                }}
                                                                value={formValue.name} required />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-12">
                                                        <div className="form-group">
                                                            <label>Phone Number</label>
                                                            <input type="text" className="form-control" placeholder="Enter Number"
                                                                name='phone'
                                                                onChange={(e) => {
                                                                    let obj = { ...formValue };
                                                                    obj['phone'] = e.target.value;
                                                                    setFormValue(obj);
                                                                }}
                                                                value={formValue.phone} required />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-12">
                                                        <div className="form-group">
                                                            <label>Email Address</label>
                                                            <input type="email" className="form-control" placeholder="Enter Email"
                                                                name='email'
                                                                onChange={(e) => {
                                                                    let obj = { ...formValue };
                                                                    obj['email'] = e.target.value;
                                                                    setFormValue(obj);
                                                                }}
                                                                value={formValue.email} required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 col-12">
                                                        <div className="form-group">
                                                            <label>Email Subject</label>
                                                            <input type="text" className="form-control" placeholder="Enter Subject"
                                                                name='subject'
                                                                onChange={(e) => {
                                                                    let obj = { ...formValue };
                                                                    obj['subject'] = e.target.value;
                                                                    setFormValue(obj);
                                                                }}
                                                                value={formValue.subject} required />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>Description</label>
                                                            <textarea className="form-control" rows="14"
                                                                placeholder="Comments"
                                                                name='message'
                                                                onChange={(e) => {
                                                                    let obj = { ...formValue };
                                                                    obj['message'] = e.target.value;
                                                                    setFormValue(obj);
                                                                }}
                                                                value={formValue.message} required></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="submit" className="btn-primary">Submit Enquiry</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-lg-6">
                                    <h3 style={{ color: '#ebbb67' }}>Contact Details</h3>
                                    {
                                        contactData !== undefined
                                            ?
                                            <>
                                                <div className="row">
                                                    <div className="col-lg-12 d-flex">
                                                        <div className="flex-fill">
                                                            <div className="contact-info-details d-flex align-items-center">
                                                                <span><img src="https://dreamsestate.dreamstechnologies.com/html/assets/img/icons/phone.svg" alt="Image" /></span>
                                                                <div>
                                                                    <h4 style={{ color: '#ebbb67' }}>Call Us At</h4>
                                                                    <a href="tel:{contactData.phone1}">{contactData.phone1}</a>,&nbsp; &nbsp;
                                                                    <a href="tel:{contactData.phone1}">{contactData.phone2}</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 d-flex">
                                                        <div className="flex-fill">
                                                            <div className="contact-info-details d-flex align-items-center">
                                                                <span><img src="https://dreamsestate.dreamstechnologies.com/html/assets/img/icons/mail.svg" alt="Image" /></span>
                                                                <div>
                                                                    <h4 style={{ color: '#ebbb67' }}>Email Us</h4>
                                                                    <a
                                                                        href="mailto:{contactData.email1}" style={{ color: '#fff' }}><span
                                                                            className="__cf_email__">{contactData.email1}</span></a>
                                                                    <a
                                                                        href="mailto:{contactData.email2}" style={{ color: '#fff' }}><span
                                                                            className="__cf_email__">{contactData.email2}</span></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 d-flex">
                                                        <div className="flex-fill">
                                                            <div className="contact-info-details d-flex align-items-center">
                                                                <span><img src="https://dreamsestate.dreamstechnologies.com/html/assets/img/icons/map-pin.svg" alt="Image" /></span>
                                                                <div>
                                                                    <h4 style={{ color: '#ebbb67' }}>Location</h4>
                                                                    <p style={{ color: '#fff' }}>{contactData.address}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="map-location">
                                                    <h3 style={{ color: '#ebbb67' }}>Find Us On</h3>
                                                    <iframe
                                                        src={contactData.map_link}
                                                        height="359"></iframe>
                                                </div>
                                            </>
                                            :
                                            ''
                                    }


                                </div>
                            </div>
                        </div>
                    </section>
                </>
            )}
            <Footer />
            <NotificationContainer />
        </div>
    )
}
