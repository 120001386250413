import React, { useEffect, useState } from 'react'
import Header from '../Common/Header'
import Footer from '../Common/Footer'
import { useParams } from 'react-router-dom'
import axios from 'axios';
import { BaseUrl } from '../Api/BaseUrl';
import { ImageBaseUrl } from '../Api/ImageBaseUrl';
import ScrollToTop from '../ScrollToTop';
import Whatsapp from '../Common/Whatsapp';
import Loader from '../Common/Loader';

export default function SellProperty() {
    let { slug } = useParams();
    let [serviveData, setServiceData] = useState({})
    const [loading, setLoading] = useState(true);

    let servicesRes = () => {
        axios.get(`${BaseUrl}/services/${slug}`)
            .then((res) => res.data)
            .then((finalRes) => {
                setServiceData(finalRes.data.service);
            })
            .catch((error) => {
                console.error('Error fetching service details:', error);
            });
    };

    useEffect(() => {
        servicesRes()
        setLoading(false);
    }, [slug])
    return (
        <div>
            <ScrollToTop />
            <Whatsapp />
            <Header />
            {loading ? (
                <Loader />
            ) : (
            <>
                <div className="container mx-auto mt-20 md:mt-28 px-4 md:px-0 flex-col flex items-start justify-start text-center">
                    {
                        serviveData !== undefined

                            ?
                            <div className="mx-auto w-full relative flex justify-center items-center">
                                <div
                                    className="w-full h-[50vh] md:h-[75vh] bg-cover rounded-3xl md:rounded-[3.25rem] bg-bottom brightness-[0.60]">
                                    <img alt="" loading="lazy" decoding="async" data-nimg="fill" className="-z-[1] rounded-3xl object-cover"
                                        src={ImageBaseUrl + '/' + serviveData.photo}
                                        style={{ position: 'absolute', height: '100%', width: '100%', inset: '0px', color: 'transparent' }} />
                                </div>

                                <p className="absolute   text-xl text-white drop-shadow-xl">
                                    <h2 className='text-[#D4B071] font-black text-4xl font-Butler mb-4'>{serviveData.title}</h2>{serviveData.sort_text}</p>
                            </div>
                            :
                            ''
                    }

                    <div className="w-full rtl:text-right ltr:text-left p-4 md:p-8">
                        <div className="flex flex-col justify-center items-center w-full ">
                            <h1 className="text-3xl lg:text-4xl font-black py-5 max-w-4xl text-center text-[#D4B071] mb-4 lg:mb-8">
                                Our Approach to Assisting You in Property Selling</h1>
                            <div className=" text-lg text-center gap-8 md:text-xl">
                                {serviveData !== undefined
                                    ?
                                    <p className='text-white' dangerouslySetInnerHTML={{ __html: serviveData.description }}></p>
                                    :
                                    ''}
                            </div>
                        </div>
                    </div>
                </div>
                {serviveData !== undefined
                    ?
                    < div
                        className="w-full h-[50vh] md:h-[75vh] bg-cover rounded-3xl md:rounded-[3.25rem] bg-bottom brightness-[0.60]">
                        <img alt="" loading="lazy" decoding="async" data-nimg="fill" className="-z-[1] object-cover"
                            src={ImageBaseUrl + '/' + serviveData.feature_icon}
                            style={{ position: 'absolute', height: '100%', width: '100%', inset: '0px', color: 'transparent' }} />
                    </div>
                    :
                    ''
                }
            </>
            )}
            <Footer />
        </div >
    )
}
