import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import Header from '../Common/Header'
import Footer from '../Common/Footer'
import axios from 'axios'
import { BaseUrl } from '../Api/BaseUrl'
import ScrollToTop from '../ScrollToTop'
import Whatsapp from '../Common/Whatsapp'
import { Link } from 'react-router-dom'
import Loader from '../Common/Loader'

export default function Faq() {
    let [faqData, setFaqData] = useState([])
    const [loading, setLoading] = useState(true);
    const faqDatashow = async () => {
        try {
            const response = await axios.get(BaseUrl + '/faq');
            const finalRes = response.data;
            const fData = finalRes.data;
            setFaqData(fData);
        } catch (error) {
            if (error.response && error.response.status === 429) {
                console.error('Too Many Requests: Please try again later.');
                // Handle the 429 error, e.g., show a notification to the user
            } else {
                console.error('Error fetching FAQ data:', error);
                // Handle other errors, e.g., show a notification to the user
            }
        }
    };

    useEffect(() => {
        faqDatashow()
        setLoading(false);
    }, [])
    return (
        <div>
            <ScrollToTop />
            <Whatsapp/>
            <Header />
            {loading ? (
                <Loader />
            ) : (
            <>
            <div className="breadcrumb">
                <div className="container">
                    <div className="bread-crumb-head">
                        <div className="breadcrumb-title">
                            <h2>FAQ</h2>
                        </div>
                        <div className="breadcrumb-list">
                            <ul>
                            <li><Link to={'/'}>Home </Link></li>
                                <li>FAQ</li>
                            </ul>
                        </div>
                    </div>
                    <div className="breadcrumb-border-img">
                        <img src="https://dreamsestate.dreamstechnologies.com/html/assets/img/bg/line-bg.png" alt="Line Image" />
                    </div>
                </div>
            </div>

            <section className="faq-section section">
                <div className="container">
                    <div className="accordion" id="accordionExample">
                    {faqData.map((faq, index) => (
                            <div className="accordion-item" key={faq.id}>
                                <h2 className="accordion-header">
                                    <button className="accordion-button faqBtnColor" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${faq.id}`} aria-expanded={index === 0} aria-controls={`collapse${faq.id}`}>
                                        {index + 1}. {faq.title}
                                    </button>
                                </h2>
                                <div id={`collapse${faq.id}`} className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`} data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <strong>{faq.details}</strong>
                                    </div>
                                </div>
                            </div>
                        ))}
                        
                    </div>
                </div>
            </section>
            </>
            )}
            <Footer />
        </div>
    )
}
