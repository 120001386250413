import React, { useEffect, useState } from 'react'
import Header from '../Common/Header'
import Footer from '../Common/Footer'
import axios from 'axios';
import { BaseUrl } from '../Api/BaseUrl';
import { ImageBaseUrl } from '../Api/ImageBaseUrl';
import ScrollToTop from '../ScrollToTop';
import Whatsapp from '../Common/Whatsapp';
import { Link } from 'react-router-dom';
import Loader from '../Common/Loader';

export default function Developer() {
    let [developer, setDeveloper] = useState([]);
    const [loading, setLoading] = useState(true);
    const developerRes = async () => {
        try {
            const response = await axios.get(BaseUrl + '/developer');
            const finalRes = response.data;
            setDeveloper(finalRes.data.developer);
        } catch (error) {
            if (error.response && error.response.status === 429) {
                console.error('Too Many Requests: Please try again later.');
                // Handle the 429 error, e.g., show a notification to the user
            } else {
                console.error('Error fetching developer data:', error);
                // Handle other errors, e.g., show a notification to the user
            }
        }
    };
    useEffect(() => {
        developerRes();
        setLoading(false)
    }, [])
    return (
        <div>
            <ScrollToTop />
            <Whatsapp/>
            <Header />
            {loading ? (
                <Loader />
            ) : (
            <>
            <div className="breadcrumb">
                <div className="container">
                    <div className="bread-crumb-head">
                        <div className="breadcrumb-title">
                            <h2>Developers</h2>
                        </div>
                        <div className="breadcrumb-list">
                            <ul>
                            <li><Link to={'/'}>Home </Link></li>
                                <li>Developers</li>
                            </ul>
                        </div>
                    </div>
                    <div className="breadcrumb-border-img">
                        <img src="https://dreamsestate.dreamstechnologies.com/html/assets/img/bg/line-bg.png" alt="Line Image" />
                    </div>
                </div>
            </div>

            <div className="container mx-auto px-4 md:px-0 mt-20 md:mt-28 mb-5">

                
                <div>
                    <div className="grid gap-10 row-gap-5 mb-8 sm:grid-cols-2 lg:grid-cols-4 grid-cols-1 md:px-32 px-3">
                        {developer.map((devData, index) => {
                            return (
                                <div className="aspect-square max-w-xs w-full my-1 px-1 relative mx-auto">
                                    <a
                                    className="relative overflow-hidden" ><img
                                        src={ImageBaseUrl+'/'+devData.photo} style={{ height: '220px',border:'5px solid #ebbb67' }}
                                        alt={devData.title} className="object-contain w-full h-full rounded-3xl shadow-xl md:p-6 p-4 bg-white " />
                                        </a>
                                </div>
                            )
                        })}


                    </div>
                    {/* <div className="w-full flex justify-center items-center ">
                        <div className="flex gap-4 mt-8"><button
                            className="flex mx-auto relative items-center justify-center bg-white border-2 border-[#D4B071]  text-black font-bold px-2 xl:px-6 py-2 rounded-full drop-shadow-lg hover:drop-shadow-2xl">Show
                            more</button></div>
                    </div> */}
                </div>
            </div>
            </>
            )}
            <Footer />
        </div>
    )
}
