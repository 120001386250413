import React, { useEffect, useState } from 'react'
import Header from '../Common/Header'
import Footer from '../Common/Footer'
import { useParams } from 'react-router-dom';
import { BaseUrl } from '../Api/BaseUrl';
import { ImageBaseUrl } from '../Api/ImageBaseUrl';
import axios from 'axios';
import ScrollToTop from '../ScrollToTop';
import Whatsapp from '../Common/Whatsapp';
import Loader from '../Common/Loader';

export default function PlanGuide() {
    let { slug } = useParams()
    let [guidedata, setGuideData] = useState({});
    const [loading, setLoading] = useState(true);
    let [guideDetails, setGuideDetails] = useState([])
    const guidePageData = async () => {
        try {
            const response = await axios.get(`${BaseUrl}/guide/${slug}`);
            const finalRes = response.data;
            const fData = finalRes.data;
            setGuideData(fData);
            setGuideDetails(finalRes.data.temp);
        } catch (error) {
            if (error.response && error.response.status === 429) {
                console.error('Too Many Requests: Please try again later.');
                // Handle the 429 error, e.g., show a notification to the user
            } else {
                console.error('Error fetching guide data:', error);
                // Handle other errors, e.g., show a notification to the user
            }
        }
    };
    useEffect(() => {
        guidePageData()
        setLoading(false);
    }, [slug])
    return (
        <div>
            <ScrollToTop />
            <Whatsapp/>
            <Header />
            {loading ? (
                <Loader />
            ) : (
            <div className="mx-auto mt-20 md:mt-28 px-4 md:px-0">
                {
                    guidedata !== undefined
                        ?
                        <div className="mx-auto w-full relative flex justify-center items-center">
                            <div
                                className="w-full h-[50vh] md:h-[75vh] bg-cover rounded-3xl md:rounded-[3.25rem] bg-bottom brightness-[0.60]">
                                <img alt="" loading="lazy" decoding="async" data-nimg="fill" className="-z-[1] rounded-3xl object-cover"
                                    src={ImageBaseUrl + '/' + guidedata.image}
                                    style={{ position: 'absolute', height: '100%', width: '100%', inset: '0px', color: 'transparent' }} />
                            </div>
                            <p className="absolute text-4xl md:text-6xl font-black text-center" style={{color:'#ebbb67'}}>{guidedata.title}</p>
                        </div>
                        :
                        ''
                }

                <div className="flex flex-col lg:flex-row items-start justify-start text-center my-5">
                    <div className="w-full mx-auto  lg:w-3/4 ">
                        <div className="w-full planCard">
                            <div className="w-full flex items-start justify-center text-center flex-wrap  ">
                                <div
                                    className="w-full mx-2 md:mx-8 bg-mainColorLight bg-opacity-5 p-2 md:p-8 rounded-2xl shadow-2xl ">
                                    {guideDetails.map((guide, index) => {
                                        return (

                                            <div key={index}
                                                className="item grid grid-col-1 items-center  md:items-start justify-start flex-wrap ltr:text-left rtl:text-right w-full mb-4 md:mb-8 ">
                                                <div className="data flex justify-start items-center w-full">
                                                    <h3
                                                        className="w-12 h-12 bg-[#D4B071] rounded-lg flex items-center justify-center rtl:ml-4 ltr:mr-4 shadow-xl font-bold text-xl md:text-3xl text-black ">
                                                        <p className="w-full text-center ">{index+1}</p>
                                                    </h3>

                                                    <h3 className="font-bold mb-2 w-full text-2xl" style={{color:'#ebbb67'}}>{guide.heading}</h3>
                                                </div>
                                                <p className="leading-loose  md:ml-[3.8rem]">{guide.text}</p>
                                            </div>


                                        )
                                    })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            )}
            <Footer />
        </div>
    )
}
