import React, { useEffect, useState } from 'react'
import Header from '../Common/Header'
import Footer from '../Common/Footer'
import { Link, useParams } from 'react-router-dom'
import { BaseUrl } from '../Api/BaseUrl';
import axios from 'axios';
import { ImageBaseUrl } from '../Api/ImageBaseUrl';
import ScrollToTop from '../ScrollToTop';
import Whatsapp from '../Common/Whatsapp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBath, faBed, faHome } from '@fortawesome/free-solid-svg-icons';
import Loader from '../Common/Loader';


export default function AreaDetails() {
    const { slug } = useParams();
    const [areaDetails, setAreaDetails] = useState({})
    let [propertyProject, setPropertyProject] = useState([]);
    let [offPlanProject, setOffPlanProject] = useState([]);
    const [loading, setLoading] = useState(true);
    const fetchAreaData = async () => {
        try {
            const response = await axios.get(`${BaseUrl}/area/${slug}`);
            const finalRes = response.data;
            setAreaDetails(finalRes.data.area);
            setOffPlanProject(finalRes.data.area.offplan_project);
            setPropertyProject(finalRes.data.area.project);
            console.log(finalRes.data.area.offplan_project);
        } catch (error) {
            if (error.response && error.response.status === 429) {
                console.error('Too Many Requests: Please try again later.');
            } else {
                console.error('Error fetching area data:', error);
            }
        }
    };
    useEffect(() => {
        fetchAreaData();
        setLoading(false);
    }, [slug]);
    return (
        <div>

            <ScrollToTop />
            <Whatsapp/>
            <Header />
            {loading ? (
                <Loader />
            ) : (
            <div className=" mx-auto pt-8 lg:pt-0 px-4 lg:px-0 mt-20 ">
                {
                    areaDetails !== undefined
                        ?
                        <section className="dark:bg-gray-900 flex flex-col justify-center items-center">
                            <div className="mx-auto w-full relative flex justify-center items-center">
                                <div
                                    className="w-full h-[50vh] md:h-[75vh] bg-cover rounded-3xl md:rounded-[3.25rem] bg-bottom brightness-[0.60]">
                                    <img alt="" loading="lazy" decoding="async" data-nimg="fill" className="-z-[1] rounded-3xl object-cover"
                                        src={ImageBaseUrl + '/' + areaDetails.photo}
                                        style={{ position: 'absolute', height: '100%', width: '100%', inset: '0px', color: 'transparent' }} />
                                </div>
                                <p className="absolute text-4xl md:text-6xl text-white font-black text-center headingTextColor">{areaDetails.title}</p>
                            </div>
                            <div className="p-4 md:p-14 space-y-10">
                                <div className="mb-10 md:mx-auto md:mb-12">
                                    <p className="text-base text-gray-50 md:text-xl text-justify text-white" > {areaDetails.sort_text}</p>
                                </div>

                                <div className="mb-10 md:mx-auto md:mb-12">
                                    <h2
                                        className="mb-6 font-sans text-3xl font-bold leading-none text-center tracking-tight text-gray-50 sm:text-4xl md:mx-auto headingTextColor">
                                        Location</h2>
                                    <p className="text-base text-gray-50 md:text-xl text-justify textWhite" dangerouslySetInnerHTML={{ __html: areaDetails.description }}></p>
                                </div>

                            </div>
                        </section>
                        :
                        ''
                }
                {propertyProject.length > 0 && (
                    <div className="row flex items-start justify-start text-center flex-wrap">
                        <div
                            className="flex  justify-between items-center overflow-hidden w-full headingBorder pb-2 mb-5">
                            <h6 className="rtl:pl-4 ltr:pr-4 inline-block font-bold text-xl lg:text-2xl headingTextColor"> Properties </h6><a className="text-md md:text-lg font-bold" href="/areas/undefined"> </a>
                        </div>
                        <div className="flex flex-col w-full">
                            <div className=" w-full flex flex-col justify-center items-center">
                                <div className="relative grid gap-5 row-gap-5 mb-8 md:grid-cols-2 lg:grid-cols-3 grid-cols-1 md:pt-8">{
                                    propertyProject.map((propertyProjectData, index) => {
                                        return (

                                            <Link to={`/project-details/${propertyProjectData.slug}`} className="px-3 ">
                                                <div className="product-custom" data-aos="fade-down" data-aos-duration="1000">
                                                    <div className="profile-widget">
                                                        <div className="doc-img">
                                                            <a href="rent-details.html" className="property-img">
                                                                <img className="img-fluid" alt="Property Image"
                                                                    src={ImageBaseUrl + '/' + propertyProjectData.photo} />
                                                            </a>
                                                            <div className="product-amount">
                                                                <span>{propertyProjectData.starting_price}</span>
                                                            </div>
                                                            

                                                        </div>
                                                        <div className="pro-content">

                                                            <h3 className="title">
                                                                <a href="rent-details.html">{propertyProjectData.title}</a>
                                                            </h3>
                                                            <p><span><i className="feather-map-pin"></i></span>{propertyProjectData.location}</p>
                                                            <ul className="d-flex details">
                                                                <li>
                                                                    <FontAwesomeIcon icon={faBed}/>
                                                                    {propertyProjectData.bed} Beds
                                                                </li>
                                                                {/* <li>
                                                                <FontAwesomeIcon icon={faBath}/>
                                                                    {propertyProjectData.bathroom} Baths
                                                                </li> */}
                                                                <li>
                                                                <FontAwesomeIcon icon={faHome}/>
                                                                    {propertyProjectData.size}
                                                                </li>
                                                            </ul>
                                                            <ul className="property-category d-flex justify-content-between ps-0 ">
                                                                <li>
                                                                    <span className="list text-black ">Listed on : </span>
                                                                    <span className="date text-black ">{propertyProjectData.completion_year}</span>
                                                                </li>
                                                                <li>
                                                                    <span className="category list text-black ">Category : </span>
                                                                    <span className="category-value date text-black ">{propertyProjectData.category.name}</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>


                                        )
                                    })
                                }

                                </div>
                            </div>
                            {/* <div className="flex gap-4 my-8"><a
                            className="flex mx-auto relative items-center justify-center bg-white border-2 border-[#D4B071]  text-black font-bold px-2 xl:px-6 py-2 rounded-full drop-shadow-lg hover:drop-shadow-2xl"
                            href="/sub-properties/7">View All Properties in Business Bay</a>
                        </div> */}
                        </div>
                    </div>
                )}
                {offPlanProject.length > 0 && (
                <div className="row flex items-start justify-start text-center flex-wrap">
                    <div
                        className="flex  justify-between items-center overflow-hidden w-full headingBorder pb-2 mb-5">
                        <h6 className="headingTextColor rtl:pl-4 ltr:pr-4 inline-block font-bold text-xl lg:text-2xl"> Off Plan Property </h6><a className="text-md md:text-lg font-bold" href="/areas/undefined"> </a>
                    </div>
                    <div className="flex flex-col w-full">
                        <div className=" w-full flex flex-col justify-center items-center">
                            <div className="relative grid gap-5 row-gap-5 mb-8 md:grid-cols-2 lg:grid-cols-3 grid-cols-1 md:pt-8">
                                {
                                    offPlanProject.map((offData, index) => {
                                        return (

                                            <Link to={`/project-details/${offData.slug}`} className="px-3 ">
                                                <div className="product-custom" data-aos="fade-down" data-aos-duration="1000">
                                                    <div className="profile-widget">
                                                        <div className="doc-img">
                                                            <a href="rent-details.html" className="property-img">
                                                                <img className="img-fluid" alt="Property Image"
                                                                    src={ImageBaseUrl + '/' + offData.photo} />
                                                            </a>
                                                            <div className="product-amount">
                                                                <span>{offData.starting_price}</span>
                                                            </div>
                                                           

                                                        </div>
                                                        <div className="pro-content">

                                                            <h3 className="title">
                                                                <a  href="rent-details.html">{offData.title}</a>
                                                            </h3>
                                                            <p><span><i className="feather-map-pin"></i></span>{offData.location}</p>
                                                            <ul className="d-flex details">
                                                                <li>
                                                                    <FontAwesomeIcon icon={faBed}/>
                                                                    
                                                                    {offData.bed} Beds
                                                                </li>
                                                                {/* <li>
                                                                <FontAwesomeIcon icon={faBath}/>
                                                                    {offData.bathroom} Baths
                                                                </li> */}
                                                                <li>
                                                                <FontAwesomeIcon icon={faHome}/>
                                                                    {offData.size}
                                                                </li>
                                                            </ul>
                                                            <ul className="property-category d-flex justify-content-between ps-0 ">
                                                                <li>
                                                                    <span className="list text-black ">Completion Year: </span>
                                                                    <span className="date text-black ">{offData.completion_year}</span>
                                                                </li>
                                                                <li>
                                                                    <span className="category list text-black ">Category : </span>
                                                                    <span className="category-value date text-black ">{offData.category.name}</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>


                                        )
                                    })
                                }
                            </div>
                        </div>
                        {/* <div className="flex gap-4 my-8"><a
                            className="flex mx-auto relative items-center justify-center bg-white border-2 border-[#D4B071]  text-black font-bold px-2 xl:px-6 py-2 rounded-full drop-shadow-lg hover:drop-shadow-2xl"
                            href="/sub-properties/7">View All Properties in Business Bay</a>
                        </div> */}
                    </div>

                </div>
                )}
            </div>
            )}
            <Footer />
        </div>
    )
}
