import React, { useEffect, useState } from 'react'
import whatsapp from '../images/whatsapp.png'
import axios from 'axios'
import { BaseUrl } from '../Api/BaseUrl'
export default function Whatsapp() {
    let [contact,setContact]=useState()

    let contactData=()=>{
        axios.get(BaseUrl+'/common_data')
        .then((res)=>res.data)
        .then((finalRes)=>{
            console.log(finalRes.data.generalSetting.phone);
            const phoneNumber = finalRes.data.generalSetting.phone.replace(/\D/g, '');
            setContact(phoneNumber)
        })
    }
    useEffect(()=>{
        contactData()
    },[])
    return (
        <div className='position-fixed' style={{ zIndex: '9999', width: '60px', right: '10px', bottom: '100px' }}>
            <a href={`https://api.whatsapp.com/send?phone=${contact}`} target="_blank" rel="noopener noreferrer">
                <img src={whatsapp} alt="" className='img-fluid ' />
            </a>
        </div>
    )
}
