import logo from './logo.svg';
import './App.css';
import Header from './Common/Header';
import Footer from './Common/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Faq from './pages/Faq';
import TermAndCondition from './pages/TermAndCondition';
import PrivacyPolicy from './pages/PrivacyPolicy';
import MeetTeam from './pages/MeetTeam';
import Blog from './pages/Blog';
import BlogDetails from './pages/BlogDetails';
import Career from './pages/Career';
import Contactus from './pages/Contactus';
import SellProperty from './pages/SellProperty';
import RentProperty from './pages/RentProperty';
import BuyProperty from './pages/BuyProperty';
import Buy from './pages/Buy';
import Rent from './pages/Rent';
import Project from './pages/Project';
import Areas from './pages/Areas';
import Developer from './pages/Developer';
import { Route, Routes } from 'react-router-dom';
import PlanGuide from './pages/PlanGuide';
import AreaDetails from './pages/AreaDetails';
import ProjectDetails from './pages/ProjectDetails';
import Brochure from './pages/Brochure';

function App() {
  return (
    <>
      <Routes>
        <Route index element={<Home />} />
        <Route path='/about-us' element={<About/>}/>
        <Route path='/buy/:id' element={<Buy/>}/>
        <Route path='/project/:slug?' element={<Project/>}/>
        <Route path='/area' element={<Areas/>}/>
        <Route path='/developer' element={<Developer/>}/>
        <Route path='/sell-property/:slug' element={<SellProperty/>}/>
        <Route path='/meet-team' element={<MeetTeam/>}/>
        <Route path='/faq' element={<Faq/>}/>
        <Route path='/blog' element={<Blog/>}/>
        <Route path='/blog-details/:slug' element={<BlogDetails/>}/>
        <Route path='/career' element={<Career/>}/>
        <Route path='/contact-us' element={<Contactus/>}/>
        <Route path='/term-and-condition/:slug' element={<TermAndCondition/>}/>
        <Route path='/plan-guide/:slug' element={<PlanGuide/>}/>
        <Route path='/area-details/:slug' element={<AreaDetails/>}/>
        <Route path='/project-details/:slug' element={<ProjectDetails/>}/>
        <Route path='/brochure' element={<Brochure/>}/>

      </Routes>
      
    </>
  );
}

export default App;
