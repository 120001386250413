import React, { useState, useEffect } from "react";
import axios from "axios";
import { BaseUrl } from "../Api/BaseUrl";
import { ImageBaseUrl } from "../Api/ImageBaseUrl";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBath,
  faBed,
  faHome,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import ScrollToTop from "../ScrollToTop";
import Whatsapp from "../Common/Whatsapp";
import Loader from "../Common/Loader";

export default function Home() {
  const [homeDetails, setHomeDetails] = useState({});
  const [homeProject, setHomeProject] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [searchCriteria, setSearchCriteria] = useState({
    keyword: "",
    address: "",
    minPrice: "",
    maxPrice: "",
  });
  const [searchError, setSearchError] = useState("");
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      const [homeResData, homeProjectData] = await Promise.all([
        axios.get(BaseUrl + "/common_data"),
        axios.get(BaseUrl + "/properties",{
          params: {
            type_id: '3'
          }
        }),
      ]);
      setHomeDetails(homeResData.data.data.generalSetting);
  
      const filteredHomeProjects = homeProjectData.data.data.project.data;
      // Filtering projects with type_id: 3
      // const filteredHomeProjects = homeProjectData.data.data.project.data.filter(
      //   (project) => project.type_id === 3
      // );
  
      setHomeProject(filteredHomeProjects);
      setFilteredProjects(filteredHomeProjects); // Update to filteredHomeProjects
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };
  

  const handleError = (error) => {
    if (error.response && error.response.status === 429) {
      console.error("Too Many Requests: Please try again later.");
    } else {
      console.error("Error:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearchCriteria((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    const { keyword, address, minPrice, maxPrice } = searchCriteria;
    const filtered = homeProject.filter(
      (project) =>
        project.title.toLowerCase().includes(keyword.toLowerCase()) &&
        project.location.toLowerCase().includes(address.toLowerCase()) &&
        (!minPrice || parseInt(project.starting_price) >= parseInt(minPrice)) &&
        (!maxPrice || parseInt(project.starting_price) <= parseInt(maxPrice))
    );
    setSearchError(filtered.length === 0 ? "Sorry, no results found." : "");
    setFilteredProjects(filtered);
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div>
      <Whatsapp />
      <ScrollToTop />
      <Header />
      {loading ? ( // Render loading component if loading is true
        <Loader />
      ) : (
        <>
          {homeDetails !== undefined ? (
            <section
              className="banner-section"
              style={{
                background: `url(${ImageBaseUrl}/${homeDetails.hero_banner}) no-repeat center center/cover`,
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <div className="banner-content" data-aos="fade-down">
                      <h1
                        dangerouslySetInnerHTML={{
                          __html: homeDetails.hero_title,
                        }}
                      ></h1>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: homeDetails.hero_text,
                        }}
                      ></p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="banner-search" data-aos="fade-down">
                      <div className="banner-tab">
                        <ul
                          className="nav nav-tabs"
                          id="bannerTab"
                          role="tablist"
                        >
                          <li className="nav-item" role="presentation">
                            <a
                              className="nav-link active"
                              id="buy-property"
                              data-bs-toggle="tab"
                              href="#buy_property"
                              role="tab"
                              aria-controls="buy_property"
                              aria-selected="true"
                            >
                              <img
                                src="https://dreamsestate.dreamstechnologies.com/html/assets/img/icons/buy-icon.svg"
                                alt="icon"
                              />{" "}
                              Buy a Property
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="tab-content" id="bannerTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="buy_property"
                          role="tabpanel"
                          aria-labelledby="buy-property"
                        >
                          <div className="banner-tab-property">
                            <form action="#" onSubmit={handleSearchSubmit}>
                              <div className="banner-property-info">
                                <div className="banner-property-grid">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Keyword"
                                    name="keyword"
                                    value={searchCriteria.keyword}
                                    onChange={handleInputChange}
                                  />
                                </div>
                                {/* <div className="banner-property-grid">
                                                    <select className="select select2-hidden-accessible">
                                                        <option value="0">Property Type</option>
                                                        <option value="1">Buy Property</option>
                                                        <option value="2">Rent Property</option>
                                                    </select>
                                                </div> */}
                                <div className="banner-property-grid">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Address"
                                    name="address"
                                    value={searchCriteria.address}
                                    onChange={handleInputChange}
                                  />
                                </div>
                                <div className="banner-property-grid">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Min Price"
                                    name="minPrice"
                                    value={searchCriteria.minPrice}
                                    onChange={handleInputChange}
                                  />
                                </div>
                                <div className="banner-property-grid">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Max Price"
                                    name="maxPrice"
                                    value={searchCriteria.maxPrice}
                                    onChange={handleInputChange}
                                  />
                                </div>
                                <div className="banner-property-grid">
                                  <button type="submit" className="btn-primary">
                                    <span>
                                      <FontAwesomeIcon icon={faSearch} />
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="rent_property"
                          role="tabpanel"
                          aria-labelledby="rent-property"
                        >
                          <div className="banner-tab-property">
                            <form action="#">
                              <div className="banner-property-info">
                                <div className="banner-property-grid">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Keyword"
                                  />
                                </div>
                                <div className="banner-property-grid">
                                  <select className="select">
                                    <option value="0">Property Type</option>
                                    <option value="1">Buy Property</option>
                                    <option value="2">Rent Property</option>
                                  </select>
                                </div>
                                <div className="banner-property-grid">
                                  <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Enter Address"
                                  />
                                </div>
                                <div className="banner-property-grid">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Min Price"
                                  />
                                </div>
                                <div className="banner-property-grid">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Max Price"
                                  />
                                </div>
                                <div className="banner-property-grid">
                                  <a
                                    href="rent-property-grid.html"
                                    className="btn-primary"
                                  >
                                    <span>
                                      <FontAwesomeIcon icon={faSearch} />
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            ""
          )}

          <section className="feature-property-sec">
            <div className="container">
              <div className="section-heading text-center">
                <h2>Featured Properties for Sales</h2>
                <div className="sec-line">
                  <span className="sec-line1"></span>
                  <span className="sec-line2"></span>
                </div>
                <p>Hand-Picked selection of quality places</p>
                <h2>{searchError && <p>{searchError}</p>}</h2>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="feature-slider owl-carousel row">
                    
                    {filteredProjects.map((proData, index) => {
                      return (
                        <Link
                          to={`/project-details/${proData.slug}`}
                          className="col-lg-4 col-md-6  px-3 productCard mb-3 "
                        >
                          <div
                            className="product-custom"
                            data-aos="fade-down"
                            data-aos-duration="1000"
                          >
                            <div className="profile-widget">
                              <div className="doc-img">
                                <a
                                  href="rent-details.html"
                                  className="property-img"
                                >
                                  <img
                                    className="img-fluid"
                                    alt="Property Image"
                                    src={ImageBaseUrl + "/" + proData.photo}
                                    style={{ height: "282px" }}
                                  />
                                </a>
                                <div className="product-amount">
                                  <span>{proData.starting_price}</span>
                                </div>
                              </div>
                              <div className="pro-content">
                                <h3 className="title">
                                  <a href="rent-details.html">
                                    {proData.title}
                                  </a>
                                </h3>
                                <p>
                                  <span>
                                    <i className="feather-map-pin"></i>
                                  </span>
                                  {proData.location}
                                </p>
                                <ul className="d-flex details">
                                  <li>
                                    <FontAwesomeIcon icon={faBed} />
                                    {proData.bed} Beds
                                  </li>
                                  {/* <li>
                                                                            <FontAwesomeIcon icon={faBath} />                                                                    {proData.bathroom} Baths
                                                                        </li> */}
                                  <li>
                                    <FontAwesomeIcon icon={faHome} />{" "}
                                    {proData.size}
                                  </li>
                                </ul>
                                <ul className="property-category d-flex justify-content-between ps-0 ">
                                  <li className="text-black">
                                    <span className="list">
                                      Completion Year :{" "}
                                    </span>
                                    <span className="date">
                                      {proData.completion_year}
                                    </span>
                                  </li>
                                  <li className="text-black">
                                    <span className="category list">
                                      Category :{" "}
                                    </span>
                                    <span className="category-value date">
                                      {proData.category.name}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </Link>
                      );
                    })}
                  </div>
                  <div
                    className="view-property-btn d-flex justify-content-center"
                    data-aos="fade-down"
                    data-aos-duration="1000"
                  >
                    <Link to={"/project"} className="btn-primary">
                      View All Properties
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="bg-imgs">
                    <img src="https://dreamsestate.dreamstechnologies.com/html/assets/img/shapes/property-sec-bg-1.png" className="bg-01" alt="icon" />
                    <img src="https://dreamsestate.dreamstechnologies.com/html/assets/img/shapes/property-sec-bg-2.png" className="bg-02" alt="icon" />
                    <img src="https://dreamsestate.dreamstechnologies.com/html/assets/img/icons/red-circle.svg" className="bg-03" alt="icon" />
                </div> */}
          </section>
        </>
      )}
      <Footer />
    </div>
  );
}
