import React, { useEffect, useState } from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import { BaseUrl } from "../Api/BaseUrl";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { ImageBaseUrl } from "../Api/ImageBaseUrl";
import ScrollToTop from "../ScrollToTop";
import Whatsapp from "../Common/Whatsapp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBath, faBed, faHome } from "@fortawesome/free-solid-svg-icons";
import Loader from "../Common/Loader";

export default function Buy() {
  let { id } = useParams();
  let [pagination, setPagination] = useState();
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  let [lastPage, setLastPage] = useState();
  const [project, setProject] = useState([]);
  const [pageTitle, setPageTitle] = useState("");

  const fetchProject = async () => {
    try {
      const res = await axios.get(`${BaseUrl}/properties?page=${currentPage}&type_id=${id}`);
      const finalRes = res.data;
      if (
        finalRes.status === "success" &&
        finalRes.data &&
        finalRes.data.project
      ) {
        // const filteredProjects = finalRes.data.project.data.filter(
        //   (project) => project.type_id.toString() === id
        // );

        setProject(finalRes.data.project.data);
        setPageTitle(getPageTitle(id));
        setLastPage(finalRes.data.project.last_page);
      }
    } catch (error) {
      if (error.response && error.response.status === 429) {
        console.error("Too Many Requests: Please try again later.");
      } else {
        console.error("Error fetching project details:", error);
      }
    }
  };
  const getPageTitle = (id) => {
    // Logic to determine the page title based on the id
    switch (id) {
      case "3":
        return "Buy";
      case "2":
        return "Off Plan";
      case "4":
        return "Rent";
      case "6":
        return "Projects";
      default:
        return "Buy";
    }
  };

  const paginationData = async () => {
    try {
      const res = await axios.get(`${BaseUrl}/properties?page=${currentPage}`);
      const finalRes = res.data;
      setLastPage(finalRes.data.project.last_page);
    } catch (error) {
      if (error.response && error.response.status === 429) {
        console.error("Too Many Requests: Please try again later.");
      } else {
        console.error("Error fetching pagination data:", error);
      }
    }
  };

  useEffect(() => {
    fetchProject();
    paginationData();
    setLoading(false);
  }, [currentPage, id]);

  const handlePageChange = (newPage) => {
    // Ensure newPage is within valid bounds
    if (newPage >= 1 && newPage <= lastPage) {
      setCurrentPage(newPage);
    }
  };

  const renderPagination = () => {
    const paginationButtons = [];

    // Previous Page Button
    paginationButtons.push(
      <li
        key="prev"
        className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
      >
        <button
          className="page-link"
          onClick={() => handlePageChange(currentPage - 1)}
        >
          Previous
        </button>
      </li>
    );

    // Numbered Page Buttons
    for (let page = 1; page <= lastPage; page++) {
      paginationButtons.push(
        <li
          key={page}
          className={`page-item ${page === currentPage ? "active" : ""}`}
        >
          <button className="page-link" onClick={() => handlePageChange(page)}>
            {page}
          </button>
        </li>
      );
    }

    // Next Page Button
    paginationButtons.push(
      <li
        key="next"
        className={`page-item ${currentPage === lastPage ? "disabled" : ""}`}
      >
        <button
          className="page-link"
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Next
        </button>
      </li>
    );

    return paginationButtons;
  };
  return (
    <div>
      <ScrollToTop />
      <Whatsapp />
      <Header />
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="breadcrumb">
            <div className="container">
              <div className="bread-crumb-head">
                <div className="breadcrumb-title">
                  <h2>{pageTitle}</h2>
                </div>
                <div className="breadcrumb-list">
                  <ul>
                    <li>
                      <Link to={"/"}>Home </Link>
                    </li>
                    <li>{pageTitle}</li>
                  </ul>
                </div>
              </div>
              <div className="breadcrumb-border-img">
                <img
                  src="https://dreamsestate.dreamstechnologies.com/html/assets/img/bg/line-bg.png"
                  alt="Line Image"
                />
              </div>
            </div>
          </div>
          <div className="propertiesList container mx-auto pt-8 md:mt-24 relative mb-5 ">
            <div className="row flex items-start justify-start text-center flex-wrap">
              <h1 className="font-bold text-2xl text-center md:text-4xl w-full my-6 md:mt-8">
                <span className="border-b-2 border-[#CDAC62] text-black px-4 md:px-4">
                  Exclusive Hot Properties for Sale in Dubai
                </span>
              </h1>

              <div className="col-md-12">
                <div className="feature-slider owl-carousel row">
                  {project.map((cardData, index) => {
                    return (
                      <Link
                        to={`/project-details/${cardData.slug}`}
                        className="col-lg-4 col-md-6  px-3 productCard mb-3 "
                      >
                        <div
                          className="product-custom"
                          data-aos="fade-down"
                          data-aos-duration="1000"
                        >
                          <div className="profile-widget">
                            <div className="doc-img">
                              <a
                                href="rent-details.html"
                                className="property-img"
                              >
                                <img
                                  className="img-fluid"
                                  alt="Property Image"
                                  src={ImageBaseUrl + "/" + cardData.photo}
                                  style={{ height: "282px" }}
                                />
                              </a>
                              <div className="product-amount">
                                <span>{cardData.starting_price}</span>
                              </div>
                            </div>
                            <div className="pro-content">
                              <h3 className="title">
                                <a href="rent-details.html">{cardData.title}</a>
                              </h3>
                              <p>
                                <span>
                                  <i className="feather-map-pin"></i>
                                </span>
                                {cardData.location}
                              </p>
                              <ul className="d-flex details">
                                <li>
                                  <FontAwesomeIcon icon={faBed} />
                                  {cardData.bed} Beds
                                </li>
                                {/* <li>
                                                                    <FontAwesomeIcon icon={faBath} />
                                                                    {cardData.bathroom} Baths
                                                                </li> */}
                                <li>
                                  <FontAwesomeIcon icon={faHome} />{" "}
                                  {cardData.size}
                                </li>
                              </ul>
                              <ul className="property-category d-flex justify-content-between ps-0 ">
                                <li className="text-black">
                                  <span className="list">
                                    Completion Year :{" "}
                                  </span>
                                  <span className="date">
                                    {cardData.completion_year}
                                  </span>
                                </li>
                                <li className="text-black">
                                  <span className="category list">
                                    Category :{" "}
                                  </span>
                                  <span className="category-value date">
                                    {cardData.category.name}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          {project.length > 0 && (
            <nav aria-label="Page navigation example" className="my-5 ">
              <ul className="pagination flex justify-content-center ">
                {renderPagination()}
              </ul>
            </nav>
          )}
        </>
      )}
      <Footer />
    </div>
  );
}
