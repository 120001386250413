import React, { useEffect, useState } from 'react';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBath, faBed, faHeart, faHome, faStar } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { BaseUrl } from '../Api/BaseUrl';
import { ImageBaseUrl } from '../Api/ImageBaseUrl';
import { Link, useParams } from 'react-router-dom';
import ScrollToTop from '../ScrollToTop';
import Whatsapp from '../Common/Whatsapp';
import Loader from '../Common/Loader';

export default function Project() {
    let [projectData, setProjectData] = useState([]);
    const [loading, setLoading] = useState(true);
    let [pagination, setPagination] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    let [lastPage, setLastPage] = useState();
    const { slug } = useParams();
    const fetchProjectDetails = async () => {
        try {
            const apiUrl = `${BaseUrl}/properties?page=${currentPage}`;
            const res = await axios.get(apiUrl);
            const finalRes = res.data;
            const filteredData = slug
            ? finalRes.data.project.data.filter(project => project.category.slug === slug)
            : finalRes.data.project.data;
        
        // Filter unique projects by title
        const uniqueProjects = [];
        const titles = new Set();
        
        filteredData.forEach(project => {
            if (!titles.has(project.title)) {
                uniqueProjects.push(project);
                titles.add(project.title);
            }
        });
        
        setProjectData(uniqueProjects);
            setLastPage(finalRes.data.project.last_page);
        } catch (error) {
            if (error.response && error.response.status === 429) {
                console.error('Too Many Requests: Please try again later.');
                // Handle the 429 error, e.g., show a notification to the user
            } else {
                console.error('Error fetching project details:', error);
                // Handle other errors, e.g., show a notification to the user
            }
        }
    };

    const paginationData = async () => {
        try {
            const res = await axios.get(`${BaseUrl}/properties?page=${currentPage}`);
            const finalRes = res.data;
            console.log(finalRes.data.project);
            setLastPage(finalRes.data.project.last_page);
        } catch (error) {
            if (error.response && error.response.status === 429) {
                console.error('Too Many Requests: Please try again later.');
                // Handle the 429 error, e.g., show a notification to the user
            } else {
                console.error('Error fetching pagination data:', error);
                // Handle other errors, e.g., show a notification to the user
            }
        }
    };

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= lastPage) {
            setCurrentPage(newPage);
            fetchProjectDetails();
        }
    };

    const renderPagination = () => {
        const paginationButtons = [];

        // Previous Page Button
        paginationButtons.push(
            <li key="prev" className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
                    Previous
                </button>
            </li>
        );

        // Numbered Page Buttons
        for (let page = 1; page <= lastPage; page++) {
            paginationButtons.push(
                <li key={page} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(page)}>
                        {page}
                    </button>
                </li>
            );
        }

        // Next Page Button
        paginationButtons.push(
            <li key="next" className={`page-item ${currentPage === lastPage ? 'disabled' : ''}`}>
                <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
                    Next
                </button>
            </li>
        );

        return paginationButtons;
    };

    useEffect(() => {
        fetchProjectDetails();
        setLoading(false)
    }, [currentPage, slug]);
    return (
        <div>
            <ScrollToTop />
            <Whatsapp />
            <Header />
            {loading ? (
                <Loader />
            ) : (
            <>
                <div className="breadcrumb">

                    <div className="container">
                        <div className="bread-crumb-head">
                            <div className="breadcrumb-title">
                                <h2>Projects</h2>
                            </div>
                            <div className="breadcrumb-list">
                                <ul>
                                    <li><Link to={'/'}>Home </Link></li>
                                    <li>{slug ? slug : 'Projects'}</li>
                                </ul>
                            </div>
                        </div>
                        <div className="breadcrumb-border-img">
                            <img src="https://dreamsestate.dreamstechnologies.com/html/assets/img/bg/line-bg.png" alt="Line Image" />
                        </div>
                    </div>

                </div>
                <div className=" mx-auto px-4 md:p-0 mt-20 md:mt-28 mb-5 ">
                    {projectData.length > 0 ? (
                        <>
                            <div className="grid gap-5 row-gap-5 mb-8 md:grid-cols-2 lg:grid-cols-3 grid-cols-1">
                                {projectData.map((proData, index) => {
                                    return (
                                        <Link to={`/project-details/${proData.slug}`} className="px-3 mb-3 ">
                                            <div className="product-custom" data-aos="fade-down" data-aos-duration="1000">
                                                <div className="profile-widget">
                                                    <div className="doc-img">
                                                        <a href="rent-details.html" className="property-img">
                                                            <img className="img-fluid" alt="Property Image"
                                                                src={ImageBaseUrl + '/' + proData.photo} />
                                                        </a>
                                                        <div className="product-amount">
                                                            <span>{proData.starting_price}</span>
                                                        </div>


                                                    </div>
                                                    <div className="pro-content">

                                                        <h3 className="title">
                                                            <a href="rent-details.html">{proData.title}</a>
                                                        </h3>
                                                        <p><span><i className="feather-map-pin"></i></span>{proData.location}</p>
                                                        <ul className="d-flex details">
                                                            <li>
                                                                <FontAwesomeIcon icon={faBed} />                                                            {proData.bed} Beds
                                                            </li>
                                                            {/* <li>
                                                                <FontAwesomeIcon icon={faBath} />
                                                                {proData.bathroom} Baths
                                                            </li> */}
                                                            <li>
                                                                <FontAwesomeIcon icon={faHome} />                                                            {proData.size}
                                                            </li>
                                                        </ul>
                                                        <ul className="property-category d-flex justify-content-between ps-0 ">
                                                            <li className='text-black'>
                                                                <span className="list">Completion Year: </span>
                                                                <span className="date">{proData.completion_year}</span>
                                                            </li>
                                                            <li className='text-black'>
                                                                <span className="category list">Category : </span>
                                                                <span className="category-value date">{proData.category.name}</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    )
                                })}
                            </div>
                            <nav aria-label="Page navigation example" className='my-5 '>
                                <ul className="pagination flex justify-content-center ">
                                    {renderPagination()}
                                </ul>
                            </nav>
                        </>
                    ) : (
                        <div className="text-center">No Data Found</div>
                    )}
                </div>
            </>
            )}
            <Footer />
        </div>
    )
}
