import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import Header from '../Common/Header'
import Footer from '../Common/Footer'
import ScrollToTop from '../ScrollToTop'
import { BaseUrl } from '../Api/BaseUrl'
import axios from 'axios'
import { Link, useParams } from 'react-router-dom'
import Whatsapp from '../Common/Whatsapp'
import Loader from '../Common/Loader'

export default function TermAndCondition() {
    let { slug } = useParams()
    let [term, setTerm] = useState()
    const [loading, setLoading] = useState(true);
    let termData = () => {
        axios.get(`${BaseUrl}/page/${slug}`)
            .then((res) => res.data)
            .then((finalRes) => {
                setTerm(finalRes.data)
            })
    }
    useEffect(() => {
        termData();
        setLoading(false);
    }, [slug])
    return (
        <div>
            <ScrollToTop />
            <Whatsapp />
            <Header />
            {loading ? (
                <Loader />
            ) : (
            <>
                {
                    term !== undefined
                        ?
                        <>
                            <div className="breadcrumb">
                                <div className="container">
                                    <div className="bread-crumb-head">
                                        <div className="breadcrumb-title">
                                            <h2>{term.title}</h2>
                                        </div>
                                        <div className="breadcrumb-list">
                                            <ul>
                                                <li><Link to={'/'}>Home </Link></li>
                                                <li>{term.title}</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="breadcrumb-border-img">
                                        <img src="https://dreamsestate.dreamstechnologies.com/html/assets/img/bg/line-bg.png" alt="Line Image" />
                                    </div>
                                </div>
                            </div>

                            <div className="section privacy-section">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="terms-policy">
                                                <p className="aos" data-aos="fade-down" dangerouslySetInnerHTML={{ __html: term.details }}></p>
                                                {/* <ul data-aos="fade-down">
                                                <li><span>
                                                    <FontAwesomeIcon icon={faCircleCheck} />
                                                </span>At vero eos et accusamus et
                                                    iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque
                                                    corrupti quos dolores et </li>
                                                <li><span>
                                                    <FontAwesomeIcon icon={faCircleCheck} />
                                                </span>Sed ut perspiciatis unde omnis
                                                    iste natus error sit voluptatem accusantium doloremque laudantium, </li>
                                                <li><span>
                                                    <FontAwesomeIcon icon={faCircleCheck} />
                                                </span>Nemo enim ipsam voluptatem quia
                                                    voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos
                                                    qui ratione voluptatem sequi nesciunt.</li>
                                                <li><span>
                                                    <FontAwesomeIcon icon={faCircleCheck} />
                                                </span>At vero eos et accusamus et
                                                    iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque
                                                    corrupti quos dolores et </li>
                                                <li><span>
                                                    <FontAwesomeIcon icon={faCircleCheck} />
                                                </span>Sed ut perspiciatis unde omnis
                                                    iste natus error sit voluptatem accusantium doloremque laudantium, </li>
                                                <li><span>
                                                    <FontAwesomeIcon icon={faCircleCheck} />
                                                </span>Nemo enim ipsam voluptatem quia
                                                    voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos
                                                    qui ratione voluptatem sequi nesciunt.</li>
                                            </ul>
                                            <p data-aos="fade-down">Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                                                accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore
                                                veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam
                                                voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni
                                                dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem
                                                ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi
                                                tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. </p>
                                            <p className="mb-0" data-aos="fade-down">Lorem ipsum dolor sit amet, consectetur adipiscing
                                                elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                                consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore
                                                eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa
                                                qui officia deserunt mollit anim id est laborum.</p> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        ''
                }
            </>
            )}
            <Footer />
        </div>
    )
}
