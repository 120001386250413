import React, { useEffect, useState } from 'react'
import Header from '../Common/Header'
import Footer from '../Common/Footer'
import { BaseUrl } from '../Api/BaseUrl';
import axios from 'axios';
import { ImageBaseUrl, imageBaseUrl } from '../Api/ImageBaseUrl';
import ScrollToTop from '../ScrollToTop';
import Whatsapp from '../Common/Whatsapp';
import { Link } from 'react-router-dom';
import Loader from '../Common/Loader';

export default function About() {
    let [aboutusData, setAboutusData] = useState({});
    const [loading, setLoading] = useState(true);
    const aboutPageData = async () => {
        try {
            const response = await axios.get(BaseUrl + '/about');
            const finalRes = response.data;
            const fData = finalRes.data;
            setAboutusData(fData);
        } catch (error) {
            if (error.response && error.response.status === 429) {
                console.error('Too Many Requests: Please try again later.');
            } else {
                console.error('Error fetching about page data:', error);
            }
        }
    };

    useEffect(() => {
        aboutPageData()
        setLoading(false);
    }, [])
    return (
        <div className='aboutus-page'>
            <Whatsapp />
            <ScrollToTop />
            <Header />
            {loading ? (
                <Loader />
            ) : (
            <>
                <div className="breadcrumb">
                    <div className="container">
                        <div className="bread-crumb-head">
                            <div className="breadcrumb-title">
                                <h2>About Us</h2>
                            </div>
                            <div className="breadcrumb-list">
                                <ul>
                                    <li><Link to={'/'}>Home </Link></li>
                                    <li>About Us</li>
                                </ul>
                            </div>
                        </div>
                        <div className="breadcrumb-border-img">
                            <img src="https://dreamsestate.dreamstechnologies.com/html/assets/img/bg/line-bg.png" alt="Line Image" />
                        </div>
                    </div>
                </div>


                <section className="aboutus-section">
                    <div className="container">
                        {
                            aboutusData !== undefined
                                ?
                                <div className="about-content">
                                    <h2>{aboutusData.header_title}</h2>
                                    <h1>{aboutusData.title}</h1>
                                    <p className="mb-0">{aboutusData.short_description}</p>
                                </div>
                                :
                                ''
                        }


                    </div>
                </section>


                <section className="about-counter-sec">
                    <div className="container">

                        <div className="section book-section ownerMessage">
                            <div className="container">
                                <div className="row align-items-center flex-row-reverse ">
                                    {aboutusData !== undefined
                                        ?
                                        <>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="book-listing text-end">
                                                    <img src={ImageBaseUrl + '/' + aboutusData.photo} alt="aboutus-03"  />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12 bg-dark p-5">

                                                <div className="book-content">

                                                    <p dangerouslySetInnerHTML={{ __html: aboutusData.description }}></p>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        ''}

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
            )}
            <Footer />
        </div>
    )
}
