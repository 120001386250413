import React, { useEffect, useState } from 'react'
import Header from '../Common/Header'
import Footer from '../Common/Footer'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Slider from 'react-slick';
import namasteVideo from '../images/namaste_properties.mp4'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import axios from 'axios';
import { BaseUrl } from '../Api/BaseUrl';
import { useParams } from 'react-router-dom';
import { ImageBaseUrl } from '../Api/ImageBaseUrl';
import cheque from '../images/cheque.svg'
import bank from '../images/bank.svg'
import creditCard from '../images/credit-card.svg'
import dolor from '../images/dollar.svg'
import ReactPlayer from 'react-player';
import ScrollToTop from '../ScrollToTop';
import Whatsapp from '../Common/Whatsapp';
import Loader from '../Common/Loader';
export default function ProjectDetails() {
    const [projectDetails, setProjectDetails] = useState({});
    const [productId, setProductId] = useState(null);
    let [shortDetails, setShortDetails] = useState()
    let [areaDetails, setAreaDetails] = useState({})
    const [galleryImages, setGalleryImages] = useState([]);
    const { slug } = useParams();
    const [amenities, setAmenities] = useState([]);
    const [loading, setLoading] = useState(true);
    const fetchProjectDetails = async () => {
        try {
            const res = await axios.get(`${BaseUrl}/property/${slug}`);
            const finalRes = res.data;
            const { Property } = finalRes.data;
            setProjectDetails(Property);
            setProductId(Property.id);
            setAreaDetails(Property.area);
            setAmenities(Property.amenities ? JSON.parse(Property.amenities) : []);
            const galleryImagesArray = Property.gallery.split(',');
            setGalleryImages(galleryImagesArray);
            setShortDetails(Property.brand.title);
            setLoading(false); // Update loading state
        } catch (error) {
            if (error.response && error.response.status === 429) {
                console.error('Too Many Requests: Please try again later.');
                // Handle the 429 error, e.g., show a notification to the user
            } else {
                console.error('Error fetching project details:', error);
                // Handle other errors, e.g., show a notification to the user
            }
            setLoading(false); // Update loading state
        }
    };



    let [formValue, setFormValue] = useState({
        name: '',
        phone: '',
        email: ''
    })
    const [errorMessage, setErrorMessage] = useState('');

    const validateForm = () => {
        // Reset error message
        setErrorMessage('');

        // Name validation: only alphabets
        if (!/^[a-zA-Z\s]+$/.test(formValue.name)) {
            setErrorMessage('Name must contain only alphabets');
            return false;
        }

        // Phone validation: only numbers
        if (!/^\d+$/.test(formValue.phone)) {
            setErrorMessage('Phone number must contain only numbers');
            return false;
        }

        // Email validation: email format
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!emailRegex.test(formValue.email)) {
            setErrorMessage('Invalid email format');
            return false;
        }

        return true; // Form is valid
    };

    let projectEnqSubmitHandler = async (event) => {
        event.preventDefault();
        if (!validateForm()) {
            // If form validation fails, do not proceed with submission
            NotificationManager.error(errorMessage, 'Please Cheack Form!!', 3000);
            return;
        }
        try {
            let formData = new FormData();

            formData.append('name', formValue.name);
            formData.append('phone', formValue.phone);
            formData.append('email', formValue.email);
            formData.append('productId', productId);
            // Log form data to console
            // for (let pair of formData.entries()) {
            //     console.log(pair[0], pair[1]);
            // }
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'accept': 'application/json'
                }
            };
            setFormValue({
                name: '',
                phone: '',
                email: ''
            });
            NotificationManager.success('Form submitted successfully', 'Success', 3000);
            const response = await axios.post(`${BaseUrl}/getInSubmit`, formData, config)
            console.log(response.data)
        } catch (error) {
            if (error.response && error.response.status === 422) {
                // NotificationManager.error('Validation Error: Check the form fields', 'Error', 3000);
                console.log('Validation Error:', error.response.data.errors);
            } else {
                console.error('Submission Error:', error);
                // NotificationManager.error('Submission Error. Please try again later.', 'Error', 3000);
            }
        }
    }
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000
    };



    useEffect(() => {
        fetchProjectDetails()
        setLoading(false);
    }, [slug])
    return (
        <div>
            <ScrollToTop />
            <Whatsapp />
            <Header />
            {loading ? (
                <Loader />
            ) : (
                <div className="bg-white mt-16 dark:bg-gray-900 relative">
                    <div className="">
                        <section>
                            {
                                projectDetails !== undefined
                                    ?
                                    <div className="relative py-4 px-4 pb-36">
                                        <img
                                            src={ImageBaseUrl + '/' + projectDetails.photo}
                                            className="absolute inset-0 object-cover w-full h-full" alt="" style={{ height: '100vh' }} />
                                        <div className="projectHero relative md:px-4 px-0 h-full lg:h-screen">
                                            <div className="relative bg-opacity-75 bg-deep-purple-accent-700 h-full">
                                                <div className="relative mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl h-full">
                                                    <div
                                                        className="flex flex-col space-y-7 md:space-y-0 items-center justify-between lg:flex-row w-full h-full">
                                                        <div
                                                            className="w-full lg:w-4/12 flex flex-col space-y-2 p-5 items-start justify-between py-16 px-6 lg:px-6 lg:py-12 bg-white/70 rounded-3xl">
                                                            <div className="w-full">
                                                                <h1
                                                                    className="w-full font-Butler mt-4 mx-2 text-2xl xl:text-4xl font-extrabold shadow-black rounded-md inline-block drop-shadow-lg text-black text-center rtl:md:text-right w-full text-[calc(20px+6vw)] md:text-[calc(20px+4vw)] lg:text-[calc(20px+1vw)]">
                                                                    {projectDetails.title}</h1>
                                                                <h3
                                                                    className="mb-4 mx-2 text-lg lg:text-xl drop-shadow-md shadow-black/20 font-black text-black tracking-wide font-semibold text-center rtl:md:text-right w-full">
                                                                    {projectDetails.location}</h3>
                                                            </div>
                                                            <div
                                                                className="grid row-gap-5  w-full flex justify-center items-center">
                                                                {/* <div
                                                                className="w-full sm:mx-auto sm:text-center p-2 md:p-5 flex flex-col justify-center items-center text-black broder-none lg:border-r-2 border-gray-800">
                                                                <p
                                                                    className="grow mb-3 text-2xl md:text-3xl font-black leading-5 font-Butler">
                                                                    {projectDetails.location}</p>
                                                                <p className="font-black text-lg md:text-xl text-center">Developer</p>
                                                            </div> */}
                                                                <div
                                                                    className="w-full sm:mx-auto sm:text-center p-5 flex flex-col justify-center items-center text-black">
                                                                    <p
                                                                        className="grow mb-3 text-2xl md:text-3xl font-black leading-5 font-Butler">
                                                                        {projectDetails.completion_year}</p>
                                                                    <p className="font-black text-lg md:text-xl text-center">Completion <br />Year
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="flex flex-col items-center lg:items-start col-start-1 col-end-3 lg:col-span-1 w-full px-5 text-black">
                                                                <p className="text-lg lg:text-2xl font-bold text-center drop-shadow-lg w-full">
                                                                    Starting Price</p>
                                                                <p className="text-3xl lg:text-4xl font-black text-center font-Butler w-full">
                                                                    {projectDetails.starting_price} </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    ''
                            }

                        </section>

                        <section className="px-3 pt-24 lg:pt-0 lg:mt-[-4rem]">
                            <div
                                className="relative p-2 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl h-full border-4 border-[#D4B071] rounded-3xl  bg-white">
                                <div className="flex justify-center items-center m-10 md:mt-[-2.5rem] mt-[-2.2rem]">
                                    <p
                                        className="text-white bg-black rounded-full inline-block text-center text-xl md:text-3xl px-5 py-2 font-bold font-Butler border-4 border-[#D4B071]">
                                        Book your Consultation</p>
                                </div>
                                <form action="#" onSubmit={projectEnqSubmitHandler}>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-3 col-12">
                                                    <div className="form-group">
                                                        <label>Name</label>
                                                        <input type="text" className="form-control" placeholder="Enter Name"
                                                            name='name'
                                                            onChange={(e) => {
                                                                let obj = { ...formValue };
                                                                obj['name'] = e.target.value;
                                                                setFormValue(obj);
                                                            }}
                                                            value={formValue.name} required />
                                                    </div>
                                                </div>

                                                <div className="col-lg-3 col-12">
                                                    <div className="form-group">
                                                        <label>Phone Number</label>
                                                        <input type="tel" className="form-control" placeholder="Enter Number"
                                                            name='phone'
                                                            onChange={(e) => {
                                                                let obj = { ...formValue };
                                                                obj['phone'] = e.target.value;
                                                                setFormValue(obj);
                                                            }}
                                                            value={formValue.phone} required />
                                                    </div>
                                                </div>

                                                <div className="col-lg-3 col-12">
                                                    <div className="form-group">
                                                        <label>Email Address</label>
                                                        <input type="email" className="form-control" placeholder="Enter Email"
                                                            name='email'
                                                            onChange={(e) => {
                                                                let obj = { ...formValue };
                                                                obj['email'] = e.target.value;
                                                                setFormValue(obj);
                                                            }}
                                                            value={formValue.email} required />
                                                    </div>
                                                </div>

                                                {/* <div className="col-lg-3 col-12">
                                                <div className="form-group">
                                                    <label>Choose Date</label>
                                                    <DatePicker placeholderText='Pick Date'
                                                        selected={selectedDate}
                                                        onChange={(date) => setSelectedDate(date)}
                                                        className="form-control"
                                                    />
                                                </div>
                                            </div> */}
                                                <div className='col-lg-3 col-12'>
                                                    <div className="form-group w-100 ">
                                                        <label></label>
                                                        <button type="submit" className="btn-primary mt-2 w-100 ">Submit</button>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </form>

                            </div>
                        </section>

                        <section id="AboutSection" className="mt-8">
                            <div className="px-4 py-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                                {areaDetails !== undefined && projectDetails !== undefined
                                    ?
                                    <>
                                        <div className="mb-10 md:mx-auto sm:text-center md:mb-12">
                                            <h2
                                                className="max-w-lg mb-4 font-sans text-center text-4xl md:text-6xl font-black leading-none tracking-tight text-gray-900 sm:text-3xl md:mx-auto font-Butler">
                                                {areaDetails.title}</h2>
                                            <p className="text-xl md:text-2xl font-semibold text-center" dangerouslySetInnerHTML={{ __html: areaDetails.description }}>
                                            </p>
                                        </div>
                                        <div className="grid gap-16 row-gap-5 md:row-gap-10 lg:grid-cols-3">
                                            <div className="bg-white flex flex-col justify-end w-full">
                                                <div className="flex items-center justify-center mb-2">
                                                    <p className="text-3xl font-extrabold text-center lg:text-4xl font-Butler">{shortDetails}</p>
                                                </div>
                                                <p className="text-xl md:text-2xl text-gray-200 text-center  border-t-4 border-[#D4B071]">
                                                    Developer</p>
                                            </div>
                                            <div className="bg-white flex flex-col justify-end w-full">
                                                <div className="flex items-center justify-center mb-2">
                                                    <p className="text-3xl font-extrabold text-center lg:text-4xl font-Butler">{projectDetails.location}</p>
                                                </div>
                                                <p className="text-xl md:text-2xl text-gray-200 text-center  border-t-4 border-[#D4B071]">
                                                    Location</p>
                                            </div>
                                            <div className="bg-white flex flex-col justify-end w-full">
                                                <div className="flex items-between justify-center mb-2 ">
                                                    <p className="text-3xl font-extrabold text-center lg:text-4xl font-Butler">{projectDetails.completion_year}</p>
                                                </div>
                                                <p className="text-xl md:text-2xl text-gray-200 text-center border-t-4 border-[#D4B071]">
                                                    Completion Year</p>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    ''}


                            </div>
                        </section>

                        <section className="Floor_Plans">
                            {projectDetails !== undefined
                                ?
                                <div className="grid md:grid-cols-12 gird-cols-1 w-full">
                                    <div className="md:col-span-8 relative">

                                        <ReactPlayer url={ImageBaseUrl + '/' + projectDetails.video_link} playing={true} controls={true}
                                            loop={true} width="100%" height="500" />
                                        {/* <video width="100%" height="500"  controls autoPlay loop muted>
                                        <source src={ImageBaseUrl + '/' + projectDetails.video_link}  type={`video/mp4`}/>
                                    </video> */}
                                    </div>
                                    <div
                                        className="w-full h-full md:col-span-4 flex justify-center md:justify-start items-center z-10 mt-4 md:mt-0">
                                        <h2
                                            className="max-w-sm text-xl font-bold tracking-wide md:leading-[2.5rem] text-gray-900 md:text-2xl leading-none text-white bg-black px-10 py-8 rounded-2xl md:rounded-[2rem] border-4 border-[#D4B071]  md:-ml-[8%]">
                                            <span className="font-Butler text-2xl md:text-4xl pb-4" dangerouslySetInnerHTML={{ __html: projectDetails.video_text }}></span></h2>
                                    </div>
                                </div>
                                :
                                ''
                            }

                        </section>

                        <section>
                            <div className="relative flex flex-col-reverse py-8 lg:py-0 lg:flex-col">
                                <div className="w-full max-w-full md px-4 md:px-4 mx-auto md:px-4 lg:px-8 lg:py-16 lg:max-w-screen-xl">
                                    <div className="flex justify-center mb-[-1.4rem] md:mb-[-1.9rem] z-10 relative">
                                        <p
                                            className="text-white bg-black rounded-full inline-block text-center text-xl md:text-4xl px-5 py-2 font-bold font-Butler border-4 border-[#D4B071]">
                                            Gallery </p>
                                    </div>
                                    <Slider {...settings}>
                                        {galleryImages.map((image, index) => (
                                            <div key={index} className="w-100">
                                                <img style={{ height: '400px' }} src={ImageBaseUrl + '/' + image} alt={`Gallery Image ${index + 1}`} className='rounded-5 w-100' />
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                            </div>
                        </section>

                        <section className=" px-4 py-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                            <div
                                className="relative p-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-2 lg:px-8 lg:py-8 h-full border-4 border-[#D4B071] rounded-[3rem]  bg-white">
                                <div
                                    className="w-full absolute left-1/2 transform -translate-x-1/2 top-[-4.2rem] lg:top-[-4.5rem] flex justify-center items-center my-10 ">
                                    <p
                                        className="text-white bg-black rounded-full inline-block text-center text-xl md:text-3xl px-5 py-2 font-bold font-Butler border-4 border-[#D4B071]">
                                        Download Floorplans</p>
                                </div>
                                <p className="font-bold text-xl md:text-2xl text-center py-5">We will email you the floor plans</p>
                                <form action="#" onSubmit={projectEnqSubmitHandler}>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-3 col-12">
                                                    <div className="form-group">
                                                        <label>Name</label>
                                                        <input type="text" className="form-control" placeholder="Enter Name"
                                                            name='name'
                                                            onChange={(e) => {
                                                                let obj = { ...formValue };
                                                                obj['name'] = e.target.value;
                                                                setFormValue(obj);
                                                            }}
                                                            value={formValue.name} required />
                                                    </div>
                                                </div>

                                                <div className="col-lg-3 col-12">
                                                    <div className="form-group">
                                                        <label>Phone Number</label>
                                                        <input type="tel" className="form-control" placeholder="Enter Number"
                                                            name='phone'
                                                            onChange={(e) => {
                                                                let obj = { ...formValue };
                                                                obj['phone'] = e.target.value;
                                                                setFormValue(obj);
                                                            }}
                                                            value={formValue.phone} required />
                                                    </div>
                                                </div>

                                                <div className="col-lg-3 col-12">
                                                    <div className="form-group">
                                                        <label>Email Address</label>
                                                        <input type="email" className="form-control" placeholder="Enter Email"
                                                            name='email'
                                                            onChange={(e) => {
                                                                let obj = { ...formValue };
                                                                obj['email'] = e.target.value;
                                                                setFormValue(obj);
                                                            }}
                                                            value={formValue.email} required />
                                                    </div>
                                                </div>
                                                <div className='col-lg-3 col-12'>
                                                    <div className="form-group w-100 ">
                                                        <label></label>
                                                        <button type="submit" className="btn-primary mt-2 w-100 ">Download Brochure</button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </form>
                            </div>
                        </section>

                        <section className="container py-8 md:py-20">
                            {projectDetails !== undefined

                                ?
                                <div className="row justify-content-center ">
                                    <div className="col-lg-6">
                                        <div className="mb-0 lg:max-w-lg ">
                                            <p
                                                className="max-w-lg mt-2 py-4 font-sans text-center text-4xl md:text-5xl font-black leading-none tracking-tight text-gray-900 sm:text-3xl md:mx-auto font-Butler ">
                                                Location</p>
                                            <p className="text-lg md:text-xl font-semibold text-center md:text-left rtl:md:text-right ">{projectDetails.location}</p>
                                            <p className="text-lg md:text-xl font-semibold text-center md:text-left rtl:md:text-right" dangerouslySetInnerHTML={{ __html: projectDetails.location_details }}></p>
                                        </div>
                                        {/* <div className="flex flex-col rtl:flex-col-reverse items-start w=full py-8 md:w-1/2  pt-4 md:pt-10 ">
                                        <div>
                                            <p className="text-xl md:text-xl font-semibold">✦ Dubai Mall and Burj Khalifa - 18
                                                minutes away</p>
                                        </div>
                                        <div>
                                            <p className="text-xl md:text-xl font-semibold text-left">✦ Global Village - 9 minutes away
                                            </p>
                                        </div>
                                        <div>
                                            <p className="text-xl md:text-xl font-semibold text-left">✦ Dubai Int. Airport(DXB) - 45
                                                minutes away</p>
                                        </div>
                                        <div>
                                            <p className="text-xl md:text-xl font-semibold text-left">✦ Dubai Outlet Mall - 15 minutes
                                                away</p>
                                        </div>
                                    </div> */}
                                    </div>
                                    <div className='col-lg-6 '>
                                        <iframe src={projectDetails.location_ifram_link} width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                    </div>
                                </div>
                                :
                                ''}

                        </section>

                        <section className=" px-4 py-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                            <div
                                className="relative p-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-2 lg:px-8 lg:py-8 h-full border-4 border-[#D4B071] rounded-[3rem]  bg-white">
                                <div
                                    className="w-full absolute left-1/2 transform -translate-x-1/2 top-[-4.2rem] lg:top-[-4.5rem] flex justify-center items-center my-10 ">
                                    <p
                                        className="text-white bg-black rounded-full inline-block text-center text-xl md:text-3xl px-5 py-2 font-bold font-Butler border-4 border-[#D4B071]">
                                        Get in touch</p>
                                </div>
                                <p className="font-bold text-xl md:text-2xl text-center py-5">Let us help you tailor your payment plan
                                </p>
                                <form action="#" onSubmit={projectEnqSubmitHandler}>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-3 col-12">
                                                    <div className="form-group">
                                                        <label>Name</label>
                                                        <input type="text" className="form-control" placeholder="Enter Name"
                                                            name='name'
                                                            onChange={(e) => {
                                                                let obj = { ...formValue };
                                                                obj['name'] = e.target.value;
                                                                setFormValue(obj);
                                                            }}
                                                            value={formValue.name} required />
                                                    </div>
                                                </div>

                                                <div className="col-lg-3 col-12">
                                                    <div className="form-group">
                                                        <label>Phone Number</label>
                                                        <input type="tel" className="form-control" placeholder="Enter Number"
                                                            name='phone'
                                                            onChange={(e) => {
                                                                let obj = { ...formValue };
                                                                obj['phone'] = e.target.value;
                                                                setFormValue(obj);
                                                            }}
                                                            value={formValue.phone} required />
                                                    </div>
                                                </div>

                                                <div className="col-lg-3 col-12">
                                                    <div className="form-group">
                                                        <label>Email Address</label>
                                                        <input type="email" className="form-control" placeholder="Enter Email"
                                                            name='email'
                                                            onChange={(e) => {
                                                                let obj = { ...formValue };
                                                                obj['email'] = e.target.value;
                                                                setFormValue(obj);
                                                            }}
                                                            value={formValue.email} required />
                                                    </div>
                                                </div>
                                                <div className='col-lg-3 col-12'>
                                                    <div className="form-group w-100 ">
                                                        <label></label>
                                                        <button type="submit" className="btn-primary mt-2 w-100 ">Submit</button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </form>
                            </div>
                        </section>
                        <section>
                            <div className="px-4 py-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
                                <div className="mb-10 md:mx-auto sm:text-center md:mb-12">
                                    <p className="max-w-lg mt-2 py-4 font-sans text-center text-4xl lg:text-5xl font-black leading-none tracking-tight text-gray-900 sm:text-3xl md:mx-auto font-Butler">
                                        Amenities
                                    </p>
                                    <p className="text-xl md:text-2xl font-semibold text-center"></p>
                                </div>
                                <div className="relative grid gap-5 grid-cols-2 lg:grid-cols-3 max-w-4xl mx-auto pt-5">
                                    {amenities.map((amenity, index) => (
                                        <div
                                            key={index}
                                            className="flex flex-col justify-between text-left transition-shadow duration-200 bg-white shadow-2xl group hover:shadow-4xl rounded-3xl"
                                        >
                                            <div className="p-10 flex flex-col justify-between items-center">
                                                <div className="flex items-center justify-center mb-4 rounded-full ">
                                                    <img src={ImageBaseUrl + '/' + amenity.image} className="w-16 drop-shadow" alt={`Amenity ${index + 1}`} />
                                                </div>
                                                <p className="mb-2 font-bold text-black text-center">{amenity.title}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                        </section>
                        <section>
                            <div className="px-4 py-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
                                <div className="mb-10 md:mx-auto text-center md:mb-12">
                                    <h2
                                        className="max-w-screen-full mb-6 font-sans text-4xl lg:text-5xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto font-Butler">
                                        We accept all types of payments</h2>
                                </div>
                                <ul className="w-full mt-8 grid gap-5 grid-cols-2 lg:grid-cols-4">

                                    <li
                                        className="flex flex-col space-y-3 md:space-y-5 justify-center items-center text-white mb-4 p-4">
                                        <img alt="" loading="lazy" width="98" height="70" decoding="async" data-nimg="1"
                                            className="md:w-12" src={cheque}
                                            style={{ color: 'transparent' }} />
                                        <h3 className="text-sm md:text-lg mx-2  text-black font-bold ">Cheques</h3>
                                    </li>
                                    <li
                                        className="flex flex-col space-y-3 md:space-y-5 justify-center items-center text-white mb-4 p-4">
                                        <img alt="" loading="lazy" width="98" height="80" decoding="async" data-nimg="1"
                                            className="md:w-12" src={creditCard}
                                            style={{ color: 'transparent' }} />
                                        <h3 className="text-sm md:text-lg mx-2  text-black font-bold">Credit Card</h3>
                                    </li>
                                    <li
                                        className="flex flex-col space-y-3 md:space-y-5 justify-center items-center text-white mb-4 p-4">
                                        <img alt="" loading="lazy" width="98" height="84" decoding="async" data-nimg="1"
                                            className="md:w-12" src={bank} style={{ color: 'transparent' }} />
                                        <h3 className="text-sm md:text-lg mx-2  text-black font-bold ">Bank Transfer</h3>
                                    </li>
                                    <li
                                        className="flex flex-col space-y-3 md:space-y-5 justify-center items-center text-white mb-4 p-4">
                                        <img alt="" loading="lazy" width="105" height="68" decoding="async" data-nimg="1"
                                            className="md:w-12" src={dolor}
                                            style={{ color: 'transparent' }} />
                                        <h3 className="text-sm md:text-lg mx-2  text-black font-bold">Cash</h3>
                                    </li>
                                </ul>
                            </div>
                        </section>
                    </div>
                </div>
            )}
            <Footer />
            <NotificationContainer />
        </div>
    )
}
