import React, { useEffect, useState } from 'react'
import Header from '../Common/Header'
import Footer from '../Common/Footer'
import axios from 'axios'
import { BaseUrl } from '../Api/BaseUrl'
import { ImageBaseUrl } from '../Api/ImageBaseUrl'
import { Link } from 'react-router-dom'
import ScrollToTop from '../ScrollToTop'
import Whatsapp from '../Common/Whatsapp'
import Loader from '../Common/Loader'

export default function Areas() {
    let [area, setArea] = useState([])
    const [loading, setLoading] = useState(true);
    const areaRes = async () => {
        try {
            const response = await axios.get(BaseUrl + '/Area');
            const finalRes = response.data;
            setArea(finalRes.data.area);
        } catch (error) {
            if (error.response && error.response.status === 429) {
                console.error('Too Many Requests: Please try again later.');
            } else {
                console.error('Error fetching areas:', error);
            }
        }
    };
    useEffect(() => {
        areaRes()
        setLoading(false)
    }, [])
    return (
        <div>
            <ScrollToTop />
            <Whatsapp />
            <Header />
            {loading ? (
                <Loader />
            ) : (
            <>
                <div className="breadcrumb">
                    <div className="container">
                        <div className="bread-crumb-head">
                            <div className="breadcrumb-title">
                                <h2>Areas</h2>
                            </div>
                            <div className="breadcrumb-list">
                                <ul>
                                    <li><Link to={'/'}>Home </Link></li>
                                    <li>Areas</li>
                                </ul>
                            </div>
                        </div>
                        <div className="breadcrumb-border-img">
                            <img src="https://dreamsestate.dreamstechnologies.com/html/assets/img/bg/line-bg.png" alt="Line Image" />
                        </div>
                    </div>
                </div>
                <div className="container Area mt-24 md:mt-32 mb-5" id="Area">
                    <div className="mx-auto">

                        <div className="AreaCards relative grid gap-5 row-gap-5 mb-8 sm:grid-cols-2 lg:grid-cols-3 grid-cols-1 mt-12 transition-all duration-1000 transform translate-y-0 delay-100 ">
                            {area.map((areaData, index) => {
                                return (
                                    <Link to={`/area-details/${areaData.slug}`} className="AreaCard flex flex-col justify-center w-full overflow-hidden bg-white bg-transparent"
                                    >
                                        <img
                                            className="object-cover w-full h-56 md:h-full cursor-pointer transition-all duration-700 hover:shadow-lg rounded-[0.8rem] md:rounded-[2.8rem] aspect-video"
                                            src={ImageBaseUrl + '/' + areaData.photo}
                                            alt={areaData.title} />
                                        <span
                                            className="text-xl font-bold text-gray-700 text-center bg-white rounded-full py-px -mt-5 z-20 text-black ">{areaData.title}</span>
                                    </Link>
                                )
                            })}

                        </div>

                    </div>
                </div>
            </>
            )}
            <Footer />
        </div>
    )
}
