import React, { useEffect, useState } from 'react';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { BaseUrl } from '../Api/BaseUrl';
import { ImageBaseUrl } from '../Api/ImageBaseUrl';
import ScrollToTop from '../ScrollToTop';
import Whatsapp from '../Common/Whatsapp';
import Loader from '../Common/Loader';

const formatDate = (dateString) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
  return formattedDate;
};

const BlogDetails = () => {
  const { slug } = useParams();
  const [blogDetails, setBlogDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [latestBlog, setLatestBlog] = useState([]);
  const fetchBlogDetails = async () => {
    try {
      const response = await axios.get(`${BaseUrl}/blog/${slug}`);
      const finalRes = response.data;
      setBlogDetails(finalRes.data.blogs);
      setLatestBlog(finalRes.data.recent_blogs);
    } catch (error) {
      if (error.response && error.response.status === 429) {
        console.error('Too Many Requests: Please try again later.');
      } else {
        console.error('Error fetching blog details:', error);
      }
    }
  };

  useEffect(() => {
    fetchBlogDetails();
    setLoading(false)
  }, [slug]);


  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // You can change this to 'auto' for an instant scroll
    });
  };
  return (
    <div>
      <ScrollToTop />
      <Whatsapp />
      <Header />
      {loading ? (
        <Loader />
      ) : (
        <div className="bg-gray-100 min-h-screen mt-20 lg:mt-28">
          <div className="bg-blue-500 text-black py-6">
            <div className="container mx-auto">
              {blogDetails !== undefined ? (
                <div className="flex flex-col items-center">
                  <h1 className="lg:text-4xl font-bold mb-6 headingTextColor">{blogDetails.title}</h1>
                  <div className="flex justify-center items-center space-x-2 divide-x font-bold mb-5">
                    <p className="text-lg px-4 py-px textWhite">Dubai</p>
                    <p className="text-lg px-4 py-px textWhite">Buy</p>
                    <p className="text-lg px-4 py-px textWhite">Apartment</p>
                  </div>
                  <p className="text-sm text-black font-bold">{formatDate(blogDetails.created_at)}</p>
                </div>
              ) : (
                'Loading...'
              )}
            </div>
          </div>
          <section className="container mx-auto py-8">
            <div className="flex flex-col justify-center items-center -mx-4">
              {blogDetails !== undefined ? (
                <div className="w-full px-4 flex flex-col items-center">
                  <div className="w-full max-w-4xl px-4">
                    <img
                      className="aspect-video w-100 max-w-full h-auto rounded-3xl"
                      src={ImageBaseUrl + '/' + blogDetails.photo}
                      alt="The Merits of Buying an Apartment in Dubai"
                    />
                  </div>
                  <div className=" mt-6 p-6 lg:p-12 rounded-lg">
                    <div className="text-gray-700 blogContent font-medium" dangerouslySetInnerHTML={{ __html: blogDetails.description }}></div>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </section>

          <section className="container mx-auto ">
            <div
              className="flex  justify-between items-center overflow-hidden w-full border-b-2 border-[#D4B071] pb-2 mb-5">
              <h6 className="rtl:pl-4 ltr:pr-4 inline-block font-bold text-xl lg:text-2xl headingTextColor my-3 "> Latest News </h6><a
                className="text-md md:text-lg font-bold" href="/blogs/undefined"> </a>
            </div>
            <div className="grid gap-12 row-gap-5 md:grid-cols-2 lg:grid-cols-4 py-20 sm:py-10 lg:py-12">
              <ScrollToTop />
              {

                latestBlog.map((newblog, index) => {
                  return (
                    <div className="inline-block bg-white rounded " key={index} style={{border:'5px solid #ebbb67'}}>
                      <div className="flex flex-col h-full">
                        <div className=" relative">
                          <img
                            src={ImageBaseUrl + '/' + newblog.photo}
                            className="object-cover rounded-3xl w-full h-44 lg:h-56" alt="blog.title" />
                        </div>
                        <div className="flex-grow border-2 border-[#D4B071] rounded-3xl mx-3 -mt-12 bg-white z-10 relative">
                          <div className="p-7 text-left flex flex-col justify-center items-center">
                            <p>{formatDate(newblog.created_at)}</p>
                            <h3 className="mb-2 text-center font-bold text-lg lg:text-xl lineClamp">{newblog.title}</h3>
                            <div className="flex flex-wrap items-center justify-between"><Link to={`/blog-details/${newblog.slug}`} onClick={scrollToTop}
                              className="w-[60%] text-center font-black absolute left-1/2 -translate-x-1/2 -bottom-4 bg-white px-4 py-px border-2 border-[#D4B071] rounded-full hover:bg-black hover:text-white transition-all duration-2000 ease-in-out"
                            >
                              <p className='mb-0 '>Read More</p>
                            </Link></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              }


            </div>
          </section>

        </div>
      )}
      <Footer />
    </div>
  );
};

export default BlogDetails;
