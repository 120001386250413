import React, { useEffect, useState } from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import axios from "axios";
import { BaseUrl } from "../Api/BaseUrl";
import { ImageBaseUrl } from "../Api/ImageBaseUrl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import ScrollToTop from "../ScrollToTop";
import Whatsapp from "../Common/Whatsapp";
import Loader from "../Common/Loader";
export default function MeetTeam() {
  let [teamData, setTeamData] = useState([]);
  const [loading, setLoading] = useState(true);
  const meetTeamData = async () => {
    try {
      const response = await axios.get(BaseUrl + "/team");
      const finalRes = response.data;
      const fData = finalRes.data;
      setTeamData(fData);
    } catch (error) {
      if (error.response && error.response.status === 429) {
        console.error("Too Many Requests: Please try again later.");
        // Handle the 429 error, e.g., show a notification to the user
      } else {
        console.error("Error fetching team data:", error);
        // Handle other errors, e.g., show a notification to the user
      }
    }
  };

  useEffect(() => {
    meetTeamData();
    setLoading(false);
  }, []);

  return (
    <div>
      <ScrollToTop />
      <Whatsapp />
      <Header />
      {loading ? (
        <Loader />
      ) : (
        <section className="container mx-auto mt-16 md:mt-28">
          <div className="flex flex-col justify-center items-center">
            <p className=" px-6 py-3 bg-black rounded-full text-[#D4B071]  md:text-3xl font-semibold">
              Meet The Team
            </p>
            <p className=" px-6 py-3 my-8 md:my-14  md:text-2xl font-semibold text-center text-white ">
              Namaste Properties Real Estate have a team of highly qualified and
              trained professional
              <br />
              consultants constantly study the changed, dynamics and market
              trends in
              <br />
              with the knowledge based information to assist them in their
              decisions.
            </p>
          </div>
          <div className="grid gap-5 row-gap-5 mb-8 sm:grid-cols-2 lg:grid-cols-3 grid-cols-1">
            {teamData.map((member, index) => (
              <div
                key={member.id}
                className={`flex flex-col justify-center items-center bg-gray-200 p-4 flex justify-center items-center ${
                  index === 0 ? "col-span-3" : "col-span-1"
                }`}
              >
                <div
                  className="shrink rounded-full overflow-hidden"
                  style={{ border: "5px solid #ebbb67" }}
                >
                  <img
                    className="aspect-square object-cover w-64"
                    src={ImageBaseUrl + "/" + member.photo}
                    alt={member.name}
                  />
                </div>
                <div className="grow py-4 grid grid-col-1 gap-y-4 w-full md:w-2/3">
                  <div className="shrink flex flex-col">
                    <p className="shrink font-bold text-lg text-center w-full text-white ">
                      {member.name}
                    </p>
                    <p className="grow font-medium text-lg text-center w-full text-white ">
                      {member.designation}
                    </p>
                  </div>
                  <div className="grow grid grid-col-1 gap-y-2 w-full">
                    {/* <p className="text-md text-black text-center w-full">Speaks: English, Arabic</p> */}
                    <div className="flex items-center w-full justify-center space-x-3">
                      <a
                        href={`tel:${member.phone}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-center"
                      >
                        <FontAwesomeIcon
                          icon={faPhone}
                          style={{ color: "#D4B071", fontSize: "30px" }}
                        />
                      </a>
                      <a
                        href={`https://api.whatsapp.com/send?phone=${encodeURIComponent(
                          member.phone.replace(/[^\d+]/g, "")
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-center"
                      >
                        <FontAwesomeIcon
                          icon={faWhatsapp}
                          style={{ color: "#D4B071", fontSize: "30px" }}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      )}
      <Footer />
    </div>
  );
}
