import React, { useEffect, useState } from 'react'
import Header from '../Common/Header'
import Footer from '../Common/Footer'
import axios from 'axios';
import { BaseUrl } from '../Api/BaseUrl';
import { ImageBaseUrl } from '../Api/ImageBaseUrl';
import Loader from '../Common/Loader';

export default function Brochure() {
    let [pdf, setPdf] = useState();
    const [loading, setLoading] = useState(true);
    let pdfData = () => {
        axios.get(BaseUrl + '/common_data')
            .then((res) => res.data)
            .then((finalRes) => {

                setPdf(finalRes.data.generalSetting.brochure)
                // console.log(finalRes.data.types)
                // console.log(finalRes.data.generalSetting)
            })
    }
    useEffect(() => {
        pdfData()
        setLoading(false);
    }, [])
    return (

        <div>
            <Header />
            {loading ? (
                <Loader />
            ) : (
                <>
            {pdf !== undefined
                ?
                <iframe
                    title="PDF Viewer"
                    src={ImageBaseUrl + '/' + pdf}
                    style={{ width: '100%', height: '600px', border: 'none' }}
                >

                </iframe>
                :
                ''}
                </>
            )}
            <Footer />
        </div>
    )
}
