// ScrollToTop.js
import React, { useLayoutEffect } from 'react';

function ScrollToTop() {
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return null; // This component doesn't render anything in the DOM
}

export default ScrollToTop;
